import { useState } from "react";
import { Create } from "@refinedev/mui";
import {
    Box,
    TextField,
    Typography,
    Stack,
    Input,
    useTheme,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AutoCompleteField from "../../components/AutoCompleteField";

import { FieldValues } from "react-hook-form";
import { supabaseClient } from "utility";
import { CancelButton } from "components/buttons/cancel";
import { SaveButton } from "components/buttons/save";
import { BackButton } from "components/buttons/back";

export const BillsCreate: React.FC<IResourceComponentsProps> = () => {
    const theme = useTheme();
    const colorMode = theme.palette.mode;
    const translate = useTranslate();

    const [isUploadLoading, setIsUploadLoading] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [record, setRecord] = useState({});

    const {
        // saveButtonProps,
        refineCore: { onFinish },
        register,
        control,
        formState: { errors },
        setValue,
        setError,
        getValues,
        handleSubmit,
        watch,
    } = useForm();
    // {
    //     refineCoreProps: {
    //         resource: "documents"
    //         id,
    //         action: "create",
    //     }
    // }

    const filesInput = watch("files");
    // const metadata = watch("Metadata");
    const accounts_id = watch("accounts_id");

    // const convertBase64 = (file: File) => {
    //   return new Promise((resolve, reject) => {
    //     const fileReader = new FileReader();
    //     fileReader.readAsDataURL(file);

    //     fileReader.onload = () => {
    //       resolve(fileReader.result);
    //     };

    //     fileReader.onerror = (error) => {
    //       reject(error);
    //     };
    //   });
    // };

    const onChangeHandler = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        try {
            setIsUploadLoading(true);

            const target = event.target;
            const file: File = (target.files as FileList)[0];

            const accounts_id = getValues("accounts_id");

            supabaseClient
                .from("accounts")
                .select("Name,vendors(Name)")
                .eq("id", accounts_id)
                .limit(1)
                .single()
                .then((record) => {
                    const vendor = (record?.data?.vendors as any).Name;
                    const parser = vendor;
                    // const base64 = await convertBase64(file);
                    const url = `http://192.168.1.117:31042/InvoiceParser/${vendor}/${parser}`;
                    //5430520000 test account
                    const formData = new FormData();
                    formData.append("file", file, file.name);
                    fetch(url, {
                        method: "POST",
                        body: formData,
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            setValue("documents", target.files as FileList, {
                                shouldValidate: true,
                            });
                            setValue("Docdate", data.Info.BillDate);
                            setValue("Reference", data.Info.BillDate);
                            setValue("Metadata", data);
                        })
                        .catch((error) => console.error("Error:", error));
                    // const entry = { base64: base64, File: file.}
                    // setValue("documents", entry as string, { shouldValidate: true });
                    setIsUploadLoading(false);
                });
        } catch (error) {
            setError("files", { message: "Upload failed. Please try again." });
            setIsUploadLoading(false);
        }
    };

    const onFinishHandler = (data: FieldValues) => {
        var documents: Array<any> = [];

        const organization_name = "powerhouse";

        Array.from({ length: data.documents.length }, (_value, index) => {
            const file_item = data.documents.item(index);
            supabaseClient.storage
                .from("uploads")
                .upload(
                    `Invoices/${organization_name}/${file_item.name}`,
                    file_item,
                    {
                        cacheControl: "3600",
                        upsert: true,
                    }
                )
                .then((data) => {
                    console.log("UPLOAD RETURN DATA", data);
                });
            const document_record = {
                path: `Invoices/${organization_name}/${file_item.name}`,
                title: file_item.name,
                mimetype: "application/pdf",
                size: file_item.size,
                icon: "mdi-pdf-box",
            };
            documents.push(document_record);
            return undefined; // Return value to satisfy Array.from()
        });

        const upload_record = {
            Type: "Invoice",
            Reference: data.Reference,
            accounts_id: data.accounts_id,
            Docdate: data.Docdate,
            Metadata: data.Metadata,
            Documents: documents,
        };
        onFinish(upload_record);
    };

    return (
        <Create
            title={
                <Typography variant="h5">
                    {translate("Bills.titles.upload")}
                </Typography>
            }
            footerButtons={[
                <CancelButton
                    key={"cancel"}
                    sx={{
                        padding: ".25rem 2.25rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                />,
                <SaveButton
                    key={"save"}
                    sx={{
                        padding: ".25rem 2.25rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                    onClick={handleSubmit(onFinishHandler)}
                >
                    Submit
                </SaveButton>,
            ]}
            goBack={<BackButton />}
            wrapperProps={{
                className: "bills",
            }}
        >
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <Stack gap={1}>
                    <AutoCompleteField
                        control={control}
                        resource="accounts"
                        field="accounts_id"
                        name="Name"
                        // defaultValue={loginsData?.vendors_id}
                        label={translate("Bills.fields.Accounts")}
                        error={!!(errors as any)?.Accounts}
                        helperText={(errors as any)?.Accounts?.message}
                    />
                    <input type="hidden" {...register("Metadata")} />
                    <>
                        <label
                            htmlFor="documents-input"
                            style={{ marginTop: ".625rem" }}
                        >
                            <Input
                                id="documents-input"
                                type="file"
                                sx={{ display: "none" }}
                                onChange={onChangeHandler}
                                disabled={!accounts_id}
                            />
                            <input
                                id="file"
                                {...register("documents", {
                                    required: "This field is required",
                                })}
                                type="hidden"
                            />
                            <LoadingButton
                                loading={isUploadLoading}
                                loadingPosition="end"
                                endIcon={<FileUploadIcon />}
                                variant="contained"
                                component="span"
                                disabled={!accounts_id}
                            >
                                Upload
                            </LoadingButton>
                            <br />
                            <Typography
                                sx={{
                                    fontSize: "0.75rem",
                                    color:
                                        colorMode === "dark"
                                            ? "rgba(255, 255, 255, 0.7)"
                                            : "rgba(0, 0, 0, 0.7)",
                                    fontStyle: "italic",
                                    mt: ".625rem",
                                }}
                            >
                                The uploaded file must be a PDF file.
                            </Typography>
                            <br />
                            {errors.documents && (
                                <Typography variant="caption" color="#fa541c">
                                    {errors.documents?.message?.toString()}
                                </Typography>
                            )}
                        </label>
                        {filesInput &&
                            Array.from(
                                { length: filesInput.length },
                                (value, index) => (
                                    <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                    >
                                        {filesInput.item(index)?.name}
                                    </Typography>
                                )
                            )}
                    </>
                    <TextField
                        {...register("Docdate", {
                            required: "This field is required",
                        })}
                        error={!!(errors as any)?.Docdate}
                        helperText={(errors as any)?.Docdate?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate("Bills.fields.Docdate")}
                        name="Docdate"
                    />
                    <TextField
                        {...register("Reference", {
                            required: "This field is required",
                        })}
                        error={!!(errors as any)?.Reference}
                        helperText={(errors as any)?.Reference?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate("Bills.fields.Reference")}
                        name="Reference"
                    />
                </Stack>
            </Box>
        </Create>
    );
};
