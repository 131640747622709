import { UrlField } from "@refinedev/mui";
import { useNotification } from "@refinedev/core";
import { supabaseClient } from "utility";
import fileDownload from "js-file-download";
import { Box, Button } from "@mui/material";

interface Props {
    billData?: string | null;
    button?: boolean;
    sx?: any;
}

export const DownloadUrlField = ({ billData, button, sx }: Props) => {
    const { open } = useNotification();

    const handleDownload = async (path: string, filename: string) => {
        const { data, error } = await supabaseClient.storage
            .from("uploads")
            .download(path);

        if (error) {
            open?.({
                type: "error",
                message: "Download Error",
                description: error.toString(),
            });
        } else {
            fileDownload(data, filename);
        }
    };

    const json_data = billData ? JSON.parse(billData) : null;
    if (json_data?.length > 0) {
        const path = json_data[0]?.path.replace("download/", "Test");
        return button ? (
            <Box sx={{ display: "flex", justifyContent: "center", m: 1 }}>
                <Button
                    onClick={() => {
                        handleDownload(path, json_data[0].title);
                    }}
                    variant="contained"
                    color="primary"
                    key={"exportButton"}
                    sx={sx}
                >
                    Download Document
                </Button>
            </Box>
        ) : (
            <UrlField
                value={path}
                component="button"
                onClick={() => {
                    handleDownload(path, json_data[0].title);
                }}
                sx={sx}
            >
                {json_data[0]?.title}
            </UrlField>
        );
    } else {
        return null;
    }
};
