import {
    PaletteColor,
    PaletteColorOptions,
    ThemeProvider,
    createTheme,
    responsiveFontSizes,
} from "@mui/material/styles";

import React, {
    PropsWithChildren,
    createContext,
    useEffect,
    useState,
} from "react";

type ColorModeContextType = {
    mode: string;
    setMode: () => void;
};

export const ColorModeContext = createContext<ColorModeContextType>(
    {} as ColorModeContextType
);

// Define the type and options for custom colors.
declare module "@mui/material/styles" {
    interface Palette {
        sunsetOrange: PaletteColor;
        sunsetOrangeHover: PaletteColor;
    }
    interface PaletteOptions {
        sunsetOrange?: PaletteColorOptions;
        sunsetOrangeHover?: PaletteColorOptions;
    }
}

// Add the  to the Button component's PropsColorOverrides interface.
declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        sunsetOrange: true;
        sunsetOrangeHover: true;
    }
}

// import { ThemeOptions } from "@mui/material/styles";
// For reference of the default theme objectefer to: https://mui.com/material-ui/customization/default-theme/
let customLightTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#44676D",
        },
        secondary: {
            main: "#44526d",
        },
        warning: {
            main: "#f7b500",
        },
        background: {
            default: "#f2f2f2",
        },
        sunsetOrange: {
            main: "#ff4c51",
        },
        sunsetOrangeHover: {
            main: "#d14444",
        },
        text: {
            primary: "#212121",
            secondary: "rgba(33, 33, 33, 0.35)",
        },
    },
    shape: {
        borderRadius: 4,
    },
    typography: {
        fontFamily: '"ProductSans-Regular", "Product Sans", sans-serif',
        fontWeightLight: 400,
        fontWeightMedium: 600,
        fontWeightRegular: 500,
        button: {
            fontWeight: 500,
        },
    },
    components: {
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    color: "#333333",
                },
                li: {
                    "& a": {
                        color: "#1f7b7b",
                        textDecoration: "none",
                    },
                },
                separator: {
                    color: "#060606",
                    opacity: 0.35,
                    marginRight: 0,
                    marginLeft: 0,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    backgroundColor: "#1f7b7b",
                    "&:hover": {
                        backgroundColor: "#01565c",
                    },
                },
                outlinedPrimary: {
                    color: "#1f7b7b",
                    borderColor: "#1f7b7b",
                    backgroundColor: "rgba(31, 123, 123, 0)",
                    "&:hover": {
                        borderColor: "#1f7b7b",
                        backgroundColor: "rgba(71, 134, 138, 0.3)",
                    },
                },
                outlinedSecondary: {
                    color: "#1f7b7b",
                    borderColor: "#1f7b7b",
                    "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.05)",
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    color: "#333333",
                    backgroundColor: "#ffffff",
                    backgroundImage: "none",
                    borderRadius: "10px",
                    boxSizing: "border-box",
                    boxShadow: "0px 10px 15px 0px rgba(0, 0, 0, 0.15)",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: "#212121",
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    color: "#212121",
                },
                actions: {
                    color: "#1f7b7b",
                    "& .CustomTablePaginationActions button": {
                        color: "#1f7b7b",
                    },
                    "& .CustomTablePaginationActions button.Mui-disabled": {
                        backgroundColor: "transparent",
                        color: "rgba(0, 0, 0, 0.26);",
                    },
                },
                select: {
                    borderRadius: ".25rem",
                    border: "1px solid #e6e6e6",
                    backgroundColor: "white",
                    paddingLeft: ".875rem",
                    "&:focus": {
                        borderRadius: ".25rem",
                        backgroundColor: "white",
                        borderColor: "#1f7b7b",
                        borderWidth: ".125rem",
                    },
                },
            },
        },
    },
    spacing: 8,
});

// Set the theme with a responsive typography.
customLightTheme = responsiveFontSizes(customLightTheme);

let customDarkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#94a8ab",
            contrastText: "#f0f0f0",
        },
        secondary: {
            main: "#717171",
        },
        warning: {
            main: "#f7b500",
        },
        background: {
            default: "#171724",
        },
        sunsetOrange: {
            main: "#ff4c51",
        },
        sunsetOrangeHover: {
            main: "#d14444",
        },
        text: {
            primary: "#ffffff",
            secondary: "rgba(255, 255, 255, 0.35)",
        },
    },
    shape: {
        borderRadius: 4,
    },
    typography: {
        fontFamily: '"ProductSans-Regular", "Product Sans", sans-serif',
        fontWeightLight: 400,
        fontWeightMedium: 600,
        fontWeightRegular: 500,
        button: {
            fontWeight: 500,
        },
    },
    components: {
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    color: "rgba(255, 255, 255, 0.8)",
                },
                li: {
                    "& a": {
                        color: "#1f7b7b",
                        textDecoration: "none",
                    },
                },
                separator: {
                    opacity: 0.35,
                    marginRight: 0,
                    marginLeft: 0,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    backgroundColor: "#1f7b7b",
                    "&:hover": {
                        backgroundColor: "#01565c",
                    },
                },
                outlinedPrimary: {
                    color: "#1f7b7b",
                    borderColor: "#1f7b7b",
                    backgroundColor: "rgba(31, 123, 123, 0)",
                    "&:hover": {
                        borderColor: "#1f7b7b",
                        backgroundColor: "rgba(71, 134, 138, 0.3)",
                    },
                },
                outlinedSecondary: {
                    color: "rgba(255, 255, 255, 0.8)",
                    borderColor: "rgba(255, 255, 255, 0.5)",
                    "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    color: "rgba(255, 255, 255, 0.8)",
                    backgroundColor: "#212133",
                    backgroundImage: "none",
                    borderRadius: "10px",
                    boxSizing: "border-box",
                    boxShadow: "0px 10px 15px 0px rgba(0, 0, 0, 0.15)",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: "rgba(255, 255, 255, 0.8)",
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    color: "rgba(255, 255, 255, 0.8)",
                },
                actions: {
                    color: "#1f7b7b",
                    "& .CustomTablePaginationActions button": {
                        color: "#1f7b7b",
                    },
                    "& .CustomTablePaginationActions button.Mui-disabled": {
                        backgroundColor: "transparent",
                        color: "rgba(255, 255, 255, 0.3)",
                    },
                },
                select: {
                    border: "1px solid rgba(255,255,255,0.5)",
                    borderRadius: "4px",
                    backgroundColor: "rgba(0,0,0,0)",
                    paddingLeft: ".875rem",
                    "&:focus": {
                        borderRadius: "4px",
                        backgroundColor: "rgba(0,0,0,0)",
                        borderColor: "#1f7b7b",
                        borderWidth: ".125rem",
                    },
                },
            },
        },
    },
    spacing: 8,
});

// Set the theme with a responsive typography.
customDarkTheme = responsiveFontSizes(customDarkTheme);

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const colorModeFromLocalStorage = localStorage.getItem("colorMode");
    const isSystemPreferenceDark = window?.matchMedia(
        "(prefers-color-scheme: dark)"
    ).matches;

    const systemPreference = isSystemPreferenceDark ? "dark" : "light";
    const [mode, setMode] = useState(
        colorModeFromLocalStorage || systemPreference
    );

    useEffect(() => {
        window.localStorage.setItem("colorMode", mode);
    }, [mode]);

    const setColorMode = () => {
        if (mode === "light") {
            setMode("dark");
        } else {
            setMode("light");
        }
    };

    return (
        <ColorModeContext.Provider
            value={{
                setMode: setColorMode,
                mode,
            }}
        >
            <ThemeProvider
                // you can change the theme colors here. example: mode === "light" ? RefineThemes.Magenta : RefineThemes.MagentaDark
                // theme={mode === "light" ? RefineThemes.Blue : RefineThemes.BlueDark}
                theme={mode === "light" ? customLightTheme : customDarkTheme}
            >
                {children}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};
