import { useContext } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography,
} from "@mui/material";
import { ColorModeContext } from "contexts/ColorModeContext";
import { useSharedState } from "contexts/SharedStateContext";
import { SavingsCardPopover } from "../SavingsCardPopover";
import { BaseRecord, useOne } from "@refinedev/core";

interface SavingsCardProps {
    title?: string | null;
    icon?: any;
    iconBackgroundColor?: string;
}

export const SavingsCard = ({
    title,
    icon,
    iconBackgroundColor,
}: SavingsCardProps) => {
    const userIdentity = JSON.parse(localStorage.getItem("profile") as string);
    const { mode } = useContext(ColorModeContext);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sharedState, setSharedState } = useSharedState();

    const handleSavingsPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setSharedState((prevState) => ({
            ...prevState,
            savingsCardPopoverOpen: !prevState.savingsCardPopoverOpen,
            anchorElSavingsCard: event.currentTarget,
        }));
    };

    const {
        data: tenant,
        isLoading,
    }: { data?: BaseRecord; isLoading: boolean } = useOne({
        resource: "tenants",
        id: userIdentity?.tenant_id,
    });

    let lastMonthSavings = null;
    let percentEarnings = null;
    if (isLoading) {
        return null;
    } else if (tenant) {
        lastMonthSavings = tenant?.data?.Metadata.last_month_savings
            ? tenant?.data?.Metadata.last_month_savings
            : 0;
        percentEarnings = tenant?.data?.Metadata.percent_earnings
            ? tenant?.data?.Metadata.percent_earnings
            : 0;
    }

    return (
        <Card
            sx={{
                padding: "2px",
                borderRadius: "10px",
                backgroundColor: mode === "dark" ? "#212133" : "#fff",
                boxSizing: "border-box",
                boxShadow: "0px 10px 15px 0px rgba(0, 0, 0, 0.15)",
                m: "1rem 0",
            }}
        >
            <CardHeader
                sx={{
                    padding: "5px",
                }}
                avatar={
                    <Box
                        sx={{
                            marginLeft: "-.5rem",
                            marginTop: "-.5rem",
                            position: "relative",
                            width: "3.5rem",
                            height: "3.5rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            "&::before": {
                                content: '""',
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                opacity: mode === "dark" ? "0.35" : "0.20",
                                padding: ".125rem",
                                boxSizing: "border-box",
                                borderBottomRightRadius: ".625rem",
                                backgroundColor: iconBackgroundColor,
                            },
                        }}
                    >
                        {icon}
                    </Box>
                }
                action={
                    <Typography
                        component={Button}
                        onClick={handleSavingsPopoverOpen}
                        variant="subtitle1"
                        sx={(theme) => ({
                            color: theme.palette.text.primary,
                            fontSize: ".9375rem",
                            textDecoration: "none",
                            textTransform: "capitalize",
                            display: `${
                                userIdentity?.role === "admin"
                                    ? "block"
                                    : "none"
                            }`,
                        })}
                    >
                        Edit
                        <SavingsCardPopover />
                    </Typography>
                }
            />
            <CardContent>
                <Box display="flex">
                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <Grid container spacing={1} direction="column">
                            <Grid item>
                                <Typography
                                    variant="h5"
                                    style={{
                                        lineHeight: 1,
                                        fontSize: "1.125rem",
                                        paddingTop: 0,
                                    }}
                                    color={
                                        mode === "dark"
                                            ? "rgba(255, 255, 255, 0.8)"
                                            : "#212121"
                                    }
                                >
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                style={{
                                    margin: ".25rem",
                                    paddingTop: ".5rem",
                                }}
                            >
                                <Typography fontSize={"1.25rem"}>
                                    ${lastMonthSavings}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                style={{
                                    margin: ".25rem",
                                    paddingTop: 0,
                                }}
                            >
                                <Typography
                                    component="span"
                                    style={{
                                        color: "#56ca00",
                                        fontSize: ".875rem",
                                    }}
                                >
                                    {percentEarnings}%
                                </Typography>
                                <Typography
                                    variant="body1"
                                    component="span"
                                    style={{
                                        marginLeft: ".875rem",
                                        color:
                                            mode === "dark"
                                                ? "rgba(255, 255, 255, 0.35)"
                                                : "rgba(33, 33, 33, 0.35)",
                                        fontSize: ".875rem",
                                    }}
                                >
                                    from last month
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};
