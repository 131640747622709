import {
    useShow,
    IResourceComponentsProps,
    useTranslate,
} from "@refinedev/core";
import { Show, TextFieldComponent as TextField } from "@refinedev/mui";
import { Typography, Box, Grid, styled, Paper } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { EditButton } from "components/buttons/edit";
import { BackButton } from "components/buttons/back";
import { useContext } from "react";
import { ColorModeContext } from "contexts/ColorModeContext";
import { ListButton } from "components/buttons/list";

export const TenantsShow: React.FC<IResourceComponentsProps> = () => {
    const { mode } = useContext(ColorModeContext);
    const translate = useTranslate();
    const { queryResult } = useShow({
        meta: {
            select: "id,Name,vendors(id,Name)",
        },
    });
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#212133" : "#fff",
        backgroundImage: "none",
        boxShadow: "none",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: "left",
    }));

    return (
        <Show
            isLoading={isLoading}
            headerButtons={[
                <ListButton
                    key={"list"}
                    sx={{
                        padding: ".25rem 1.6875rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                >
                    View All Tenants
                </ListButton>,
            ]}
            footerButtons={[
                <EditButton
                    key={"edit"}
                    sx={{
                        padding: ".25rem 2.25rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                >
                    Edit
                </EditButton>,
            ]}
            footerButtonProps={{
                sx: { justifyContent: "flex-end", p: "16px", mb: ".625rem" },
            }}
            goBack={<BackButton />}
            wrapperProps={{
                className: "tenants",
            }}
        >
            <Box display="flex">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={3}>
                        <Grid
                            container
                            item
                            spacing={2}
                            sm={12}
                            lg={6}
                            direction="column"
                        >
                            <Grid
                                item
                                container
                                sx={{
                                    justifyItems: "flex-start",
                                    alignItems: "center",
                                    wordBreak: "break-word",
                                }}
                            >
                                <Grid item xs={12} sm={4}>
                                    <Item>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                        >
                                            {translate("Tenants.fields.Name")}
                                        </Typography>
                                    </Item>
                                </Grid>
                                <Grid item>
                                    <Item>
                                        <TextField value={record?.Name} />
                                    </Item>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            spacing={2}
                            sm={12}
                            lg={6}
                            direction="column"
                        >
                            <Grid
                                item
                                container
                                sx={{
                                    justifyItems: "flex-start",
                                    alignItems: "flex-start",
                                    wordBreak: "break-word",
                                }}
                            >
                                <Grid item xs={12} sm={4} lg={3}>
                                    <Item>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                        >
                                            {translate(
                                                "Tenants.fields.Vendors"
                                            )}
                                        </Typography>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={8} lg={9}>
                                    <Item>
                                        <List
                                            dense={true}
                                            disablePadding
                                            style={{
                                                maxHeight: "12.5rem",
                                                overflow: "auto",
                                                border:
                                                    mode === "dark"
                                                        ? "1px solid rgba(255, 255, 255, 0.1)"
                                                        : "1px solid rgba(0, 0, 0, 0.1)",
                                                borderRadius: "4px",
                                                padding: ".125rem .4375rem",
                                            }}
                                        >
                                            {record?.vendors?.length &&
                                                record?.vendors?.map(
                                                    (item: any) => {
                                                        return (
                                                            <ListItem
                                                                disablePadding
                                                                key={item.id}
                                                            >
                                                                <ListItemText
                                                                    primary={
                                                                        item.Name
                                                                    }
                                                                    // secondary={secondary ? 'Secondary text' : null}
                                                                />
                                                            </ListItem>
                                                        );
                                                    }
                                                )}
                                        </List>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Show>
    );
};
