import React, { useEffect, useState } from "react";
import { useDataGrid, EditButton, ShowButton, List } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { ChipField } from "../../components/ChipField";

import { vendorTypes } from "./config";
import { TablePaginationActions } from "components/TablePaginationActions";

export const VendorsList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState({});

    useEffect(() => {
        // Update filters based on search text
        if (searchText !== "") {
            setFilters({
                permanent: [
                    { field: "Name", operator: "contains", value: searchText },
                ],
            });
        } else {
            setFilters({});
        }
    }, [searchText]);

    const { dataGridProps } = useDataGrid({
        filters: filters,
        meta: {
            select: "id,Name,Type,accounts(count),logins(count)", //AccountsCount
        },
    });

    const columnVisible = {
        id: false,
    };

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("Vendors.fields.id"),
                type: "number",
                minWidth: 50,
                show: false,
            },
            {
                field: "Name",
                flex: 1,
                headerName: translate("Vendors.fields.Name"),
                minWidth: 200,
            },
            {
                field: "Type",
                flex: 1,
                headerName: translate("Vendors.fields.Type"),
                minWidth: 200,
                renderCell: function render({ value }) {
                    return (
                        value && (
                            <ChipField
                                selected={value?.split(",")}
                                options={vendorTypes}
                            />
                        )
                    );
                },
            },
            {
                field: "accounts",
                flex: 1,
                headerName: translate("Vendors.fields.AccountsCount"),
                minWidth: 200,
                renderCell: function render({ value }) {
                    return value[0].count;
                },
            },
            {
                field: "logins",
                flex: 1,
                headerName: translate("Vendors.fields.LoginsCount"),
                minWidth: 200,
                renderCell: function render({ value }) {
                    return value[0].count;
                },
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row.id} />
                            <ShowButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
                disableColumnMenu: true,
            },
        ],
        [translate]
    );

    const handleSearch = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setSearchText(event.target.value);
    };

    return (
        <Box>
            <List
                wrapperProps={{
                    className: "vendors",
                }}
            >
                <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchText}
                    onChange={handleSearch}
                    style={{ margin: "-20px 10px 10px 0", width: "16rem" }}
                />
                <DataGrid
                    {...dataGridProps}
                    columns={columns}
                    columnVisibilityModel={columnVisible}
                    autoHeight
                    slotProps={{
                        pagination: {
                            ActionsComponent: TablePaginationActions
                        }
                    }}
                />
            </List>
        </Box>
    );
};
