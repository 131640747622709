import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { ColorModeContextProvider } from "contexts/ColorModeContext";
import { SharedStateProvider } from "contexts/SharedStateContext";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <React.Suspense fallback="loading">
            <ColorModeContextProvider>
                <SharedStateProvider>
                    <App />
                </SharedStateProvider>
            </ColorModeContextProvider>
        </React.Suspense>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
