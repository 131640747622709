import React from "react";

import { useState } from "react";

import { useDataGrid, List } from "@refinedev/mui";

import { IResourceComponentsProps } from "@refinedev/core";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AppsIcon from "@mui/icons-material/Apps";

import { LocationsListView } from "./components/list-view";
import { LocationsTableView } from "./components/table-view";
import { CreateButton } from "components/buttons/create";

export const LocationsList: React.FC<IResourceComponentsProps> = () => {
    const [view, setView] = useState();

    const { dataGridProps } = useDataGrid({
        meta: {
            select: "id,Name,Floor,Units,Country,City,Region,PostalCode",
        },
    });

    const onViewChange = (
        _event: any,
        view: React.SetStateAction<undefined>
    ) => {
        console.log(view);
        setView(view);
    };

    return (
        <div className="page-container">
            <List
                headerButtons={() => {
                    return (
                        <>
                            <CreateButton
                                key={"createButton"}
                                sx={{
                                    padding: ".25rem 1.25rem",
                                    minWidth: "7.5rem",
                                    textTransform: "capitalize",
                                }}
                            >
                                Create Location
                            </CreateButton>
                            <ToggleButtonGroup
                                exclusive
                                onChange={onViewChange}
                                value={view}
                            >
                                <ToggleButton
                                    size="small"
                                    value="list"
                                    aria-label="list"
                                >
                                    <ListAltIcon />
                                </ToggleButton>
                                <ToggleButton
                                    size="small"
                                    value="card"
                                    aria-label="card"
                                >
                                    <AppsIcon />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </>
                    );
                }}
                contentProps={{
                    style: {
                        marginTop: "28px",
                    },
                }}
                wrapperProps={{
                    className: "locations",
                }}
            >
                {view === "list" ? (
                    <LocationsListView locations={dataGridProps} />
                ) : (
                    <LocationsTableView />
                )}
            </List>
        </div>
    );
};
