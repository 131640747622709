import React, { useEffect, useState } from "react";
import { Edit } from "@refinedev/mui";
import {
    Box,
    Switch,
    TextField,
    FormGroup,
    FormControlLabel,
    Grid,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

import AutoCompleteField from "../../components/AutoCompleteField";
import LoadingIndicator from "components/LoadingIndicator";
import { FieldValues } from "react-hook-form";
import { CancelButton } from "components/buttons/cancel";
import { SaveButton } from "components/buttons/save";
import { BackButton } from "components/buttons/back";
import { ListButton } from "components/buttons/list";

export const NotificationsEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {
        // saveButtonProps,
        refineCore: { queryResult, formLoading, onFinish },
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const NotificationsData = queryResult?.data?.data;

    const [isResolved, setIsResolved] = useState(false);

    useEffect(() => {
        if (NotificationsData) {
            setIsResolved(NotificationsData?.resolved);
        }
    }, [NotificationsData]);

    const onFinishHandler = (data: FieldValues) => {
        const notifications_data = { ...data };

        console.log("Submitting notification page data", notifications_data);

        onFinish(notifications_data);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsResolved(event.target.checked);
    };

    return (
        <Edit
            headerButtons={[
                <ListButton
                    key={"list"}
                    sx={{
                        padding: ".25rem 1.6875rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                >
                    View All Notifications
                </ListButton>,
            ]}
            footerButtons={[
                <CancelButton
                    key={"cancel"}
                    sx={{
                        padding: ".25rem 2.25rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                />,
                <SaveButton
                    key={"save"}
                    sx={{
                        padding: ".25rem 2.25rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                    onClick={handleSubmit(onFinishHandler)}
                >
                    Update
                </SaveButton>,
            ]}
            goBack={<BackButton />}
            wrapperProps={{
                className: "notifications",
            }}
        >
            {formLoading ? (
                <LoadingIndicator />
            ) : (
                <Box
                    component="form"
                    sx={{ display: "flex", flexDirection: "column" }}
                    autoComplete="off"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...register("title", {
                                    required: "This field is required",
                                })}
                                error={!!(errors as any)?.title}
                                helperText={(errors as any)?.title?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                label={translate("Notifications.fields.Title")}
                                name="title"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AutoCompleteField
                                id="tenant_id"
                                control={control}
                                resource="tenants"
                                field="tenant_id"
                                name="Name"
                                defaultValue={NotificationsData?.tenant_id}
                                label={translate("Notifications.fields.Tenant")}
                                error={!!(errors as any)?.tenant_id}
                                helperText={(errors as any)?.tenant_id?.message}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...register("details")}
                                error={!!(errors as any)?.details}
                                helperText={(errors as any)?.details?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                label={translate(
                                    "Notifications.fields.Details"
                                )}
                                name="details"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...register("notes")}
                                error={!!(errors as any)?.notes}
                                helperText={(errors as any)?.notes?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                label={translate("Notifications.fields.Notes")}
                                name="notes"
                                multiline
                            />
                        </Grid>
                    </Grid>

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    {...register("resolved")}
                                    checked={isResolved}
                                    onChange={handleChange}
                                    inputProps={{ "aria-label": "resolved" }}
                                />
                            }
                            label={translate("Notifications.fields.Resolved")}
                        />
                    </FormGroup>
                </Box>
            )}
        </Edit>
    );
};
