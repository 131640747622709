import { Edit } from "@refinedev/mui";
import { Box, Grid, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import MultipleSelectField from "../../components/MultipleSelectField";

import { vendorTypes } from "./config";
import { CancelButton } from "components/buttons/cancel";
import { SaveButton } from "components/buttons/save";
import { BackButton } from "components/buttons/back";
import { ListButton } from "components/buttons/list";

export const VendorsEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {
        saveButtonProps,
        // refineCore: { queryResult },
        register,
        control,
        formState: { errors },
    } = useForm();

    return (
        <Edit
            headerButtons={[
                <ListButton
                    key={"list"}
                    sx={{
                        padding: ".25rem 1.6875rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                >
                    View All Vendors
                </ListButton>,
            ]}
            saveButtonProps={saveButtonProps}
            footerButtons={[
                <CancelButton
                    key={"cancel"}
                    sx={{
                        padding: ".25rem 2.25rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                />,
                <SaveButton
                    key={"save"}
                    sx={{
                        padding: ".25rem 2.25rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                >
                    Update
                </SaveButton>,
            ]}
            goBack={<BackButton />}
            wrapperProps={{
                className: "vendors",
            }}
        >
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            {...register("Name", {
                                required: "This field is required",
                            })}
                            error={!!(errors as any)?.Name}
                            helperText={(errors as any)?.Name?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label={translate("Vendors.fields.Name")}
                            name="Name"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MultipleSelectField
                            id="Type"
                            name="Type"
                            label="Type"
                            control={control}
                            defaultValue={""}
                            variant="outlined"
                            margin="normal"
                            errors={errors}
                            options={vendorTypes}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Edit>
    );
};
