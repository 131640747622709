import React, { useContext } from "react";
import {
    useDelete,
    useTranslate,
    useMutationMode,
    useCan,
    useResource,
    pickNotDeprecated,
    useWarnAboutChange,
    AccessControlContext,
} from "@refinedev/core";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// Types.
import type { DeleteButtonProps } from "@refinedev/mui";
import { Typography } from "@mui/material";
import { ColorModeContext } from "contexts/ColorModeContext";

/**
 * `<DeleteButton>` uses Material UI {@link https://mui.com/material-ui/api/loading-button/#main-content `<LoadingButton>`} and {@link https://mui.com/material-ui/react-dialog/#main-content `<Dialog>`} components.
 * When you try to delete something, a dialog modal shows up and asks for confirmation. When confirmed it executes the `useDelete` method provided by your `dataProvider`.
 *
 * @see {@link https://refine.dev/docs/api-reference/mui/components/buttons/delete-button} for more details.
 */
export const DeleteButton: React.FC<DeleteButtonProps> = ({
    resource: resourceNameFromProps,
    resourceNameOrRouteName,
    recordItemId,
    onSuccess,
    mutationMode: mutationModeProp,
    children,
    successNotification,
    errorNotification,
    hideText = false,
    accessControl,
    meta,
    metaData,
    dataProviderName,
    confirmTitle,
    confirmOkText,
    confirmCancelText,
    svgIconProps,
    invalidates,
    ...rest
}) => {
    const accessControlContext = useContext(AccessControlContext);

    const accessControlEnabled =
        accessControl?.enabled ??
        accessControlContext.options.buttons.enableAccessControl;

    const hideIfUnauthorized =
        accessControl?.hideIfUnauthorized ??
        accessControlContext.options.buttons.hideIfUnauthorized;
    const translate = useTranslate();
    const { mode: colorMode } = useContext(ColorModeContext);

    const { id, resource, identifier } = useResource(
        resourceNameFromProps ?? resourceNameOrRouteName
    );

    const { mutationMode: mutationModeContext } = useMutationMode();

    const mutationMode = mutationModeProp ?? mutationModeContext;

    const { mutate, isLoading, variables } = useDelete();

    const { data } = useCan({
        resource: resource?.name,
        action: "delete",
        params: { id: recordItemId ?? id, resource },
        queryOptions: {
            enabled: accessControlEnabled,
        },
    });

    const disabledTitle = () => {
        if (data?.can) return "";
        else if (data?.reason) return data.reason;
        else
            return translate(
                "buttons.notAccessTitle",
                "You don't have permission to access"
            );
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseOnConfirm = () => {
        if ((recordItemId ?? id) && identifier) {
            setWarnWhen(false);
            setOpen(false);
            mutate(
                {
                    id: recordItemId ?? id ?? "",
                    resource: identifier,
                    mutationMode,
                    successNotification,
                    errorNotification,
                    meta: pickNotDeprecated(meta, metaData),
                    metaData: pickNotDeprecated(meta, metaData),
                    dataProviderName,
                    invalidates,
                },
                {
                    onSuccess: (value) => {
                        onSuccess && onSuccess(value);
                    },
                }
            );
        }
    };

    const { sx, ...restProps } = rest;

    const { setWarnWhen } = useWarnAboutChange();

    if (accessControlEnabled && hideIfUnauthorized && !data?.can) {
        return null;
    }

    return (
        <div>
            <LoadingButton
                color="error"
                onClick={handleClickOpen}
                disabled={data?.can === false}
                loading={(recordItemId ?? id) === variables?.id && isLoading}
                // startIcon={!hideText && <DeleteOutline {...svgIconProps} />}
                title={disabledTitle()}
                sx={{ minWidth: 0, ...sx }}
                // loadingPosition={hideText ? "center" : "start"}
                className="DeleteButton"
                variant={hideText ? "text" : "outlined"}
                {...restProps}
            >
                {hideText ? (
                    <DeleteOutline fontSize="small" {...svgIconProps} />
                ) : (
                    children ?? translate("buttons.delete", "Delete")
                )}
            </LoadingButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    "& .MuiDialog-paper": {
                        backgroundColor:
                            colorMode === "light" ? "#ffffff" : "#212133",
                        backgroundImage: "none",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: ".625rem",
                        p: "3rem",
                    },
                }}
            >
                <ErrorOutlineIcon color="warning" sx={{ fontSize: "5rem" }} />
                <DialogTitle
                    id="alert-dialog-title"
                    style={{
                        color:
                            colorMode === "light"
                                ? "#333333"
                                : "rgba(255, 255, 255, 0.8)",
                    }}
                >
                    {confirmTitle ??
                        translate("buttons.confirm", "Are you sure?")}
                </DialogTitle>
                <Typography
                    id="alert-dialog-description"
                    sx={{
                        mb: "2.8rem",
                        color:
                            colorMode === "light"
                                ? "#333333"
                                : "rgba(255, 255, 255, 0.8)",
                    }}
                >
                    This action cannot be undone
                </Typography>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        sx={{
                            padding: ".25rem 2.25rem",
                            minWidth: "7.5rem",
                            textTransform: "capitalize",
                        }}
                        onClick={handleClose}
                    >
                        {confirmCancelText ??
                            translate("buttons.cancel", "Cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={handleCloseOnConfirm}
                        autoFocus
                        sx={{
                            padding: ".25rem 1.25rem",
                            minWidth: "7.5rem",
                            textTransform: "capitalize",
                            ml: "1.25rem",
                        }}
                    >
                        {confirmOkText ?? translate("buttons.delete", "Delete")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
