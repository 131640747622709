import React, { useState, useEffect } from "react";
import { supabaseClient } from "utility";
import * as pdfjs from "pdfjs-dist"; // Import pdfjs from pdfjs-dist
import "pdfjs-dist/build/pdf.worker.min"; // Import the worker source file
import Box from "@mui/material/Box";
import LoadingIndicator from "./LoadingIndicator";

const PdfPreview = ({ billData }: { billData: string }) => {
    const [pdfPages, setPdfPages] = useState([]);

    useEffect(() => {
        async function fetchPdfPages() {
            try {
                const docs = JSON.parse(billData); // Parse the billData JSON data
                const path = docs?.[0]?.path.replace("download/", "Test"); // Extract the path

                if (!path) {
                    console.error("Error: Path not found in the billData.");
                    return;
                }

                // Download the PDF file from Supabase Storage
                const { data, error } = await supabaseClient.storage
                    .from("uploads")
                    .download(path);

                if (error) {
                    console.error("Error downloading PDF:", error.message);
                    return;
                }

                // Convert PDF pages to images using pdfjs-dist library.
                const arrayBuffer = await data.arrayBuffer();

                const pdf = await pdfjs.getDocument(new Uint8Array(arrayBuffer))
                    .promise;
                const pages: any = [];

                for (let i = 1; i <= pdf.numPages; i++) {
                    const page = await pdf.getPage(i);
                    const scale = 1.5;
                    const viewport = page.getViewport({ scale });
                    const canvas = document.createElement("canvas");
                    const context = canvas.getContext("2d");
                    if (context) {
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;
                        await page.render({ canvasContext: context, viewport })
                            .promise;
                        pages.push(canvas.toDataURL("image/png"));
                    } else {
                        console.error(
                            "Error: Unable to get 2d context for canvas"
                        );
                    }
                }

                setPdfPages(pages);
            } catch (error: any) {
                console.error("Error fetching PDF pages:", error.message);
            }
        }

        fetchPdfPages();
    }, [billData]);

    return (
        <Box>
            {pdfPages.length > 0 ? (
                pdfPages.map((page, index) => (
                    <img
                        key={index}
                        src={page}
                        alt={`Page ${index + 1}`}
                        width={"100%"}
                        height={"100%"}
                    />
                ))
            ) : (
                <LoadingIndicator />
            )}
        </Box>
    );
};

export default PdfPreview;
