import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import BusinessIcon from "@mui/icons-material/Business";
import GroupsIcon from "@mui/icons-material/Groups";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

import { Box } from "@mui/material";
import Grid from "@mui/material/Grid"; // Grid version 1

import { ResourceCard } from "./cards/ResourceCard";
import { SavingsCard } from "./cards/SavingsCard";
import { RecentBillsCard } from "./cards/RecentBillsCard";
import { RecentNotificationsCard } from "./cards/RecentNotificationsCard";
import { AccountDataChartCard } from "./cards/AccountDataChartCard";

export const DashboardPage: React.FC = () => {
    const identity = JSON.parse(localStorage.getItem("profile") as string);

    return (
        <Box display="flex">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid container spacing={4} columns={10}>
                        <Grid item xs={10} sm={5} lg={2}>
                            <ResourceCard
                                title="Vendors"
                                resource="vendors"
                                icon={
                                    <BusinessIcon
                                        fontSize="large"
                                        style={{ color: "#0091ff" }}
                                    />
                                }
                                iconBackgroundColor="#0091ff"
                                slug="Vendors"
                            />
                        </Grid>
                        {(identity.role === "manager" ||
                            identity.role === "admin") && (
                            <Grid item xs={10} sm={5} lg={2}>
                                <ResourceCard
                                    title="Clients"
                                    resource="clients"
                                    icon={
                                        <GroupsIcon
                                            fontSize="large"
                                            style={{ color: "#0daa72" }}
                                        />
                                    }
                                    iconBackgroundColor="#0daa72"
                                    slug="Clients"
                                />
                            </Grid>
                        )}
                        <Grid item xs={10} sm={5} lg={2}>
                            <ResourceCard
                                title="Accounts"
                                resource="accounts"
                                icon={
                                    <DescriptionIcon
                                        fontSize="large"
                                        style={{ color: "#f7b500" }}
                                    />
                                }
                                iconBackgroundColor="#f7b500"
                                slug="Accounts"
                            />
                        </Grid>
                        <Grid item xs={10} sm={5} lg={2}>
                            <ResourceCard
                                title="Bills"
                                resource="documents"
                                icon={
                                    <ReceiptLongIcon
                                        fontSize="large"
                                        style={{ color: "#58aa02" }}
                                    />
                                }
                                iconBackgroundColor="#58aa02"
                                slug="Bills"
                            />
                        </Grid>
                        <Grid item xs={10} sm={5} lg={2}>
                            <SavingsCard
                                title="Savings"
                                icon={
                                    <MonetizationOnIcon
                                        fontSize="large"
                                        style={{ color: "#52c0c0" }}
                                    />
                                }
                                iconBackgroundColor="#52c0c0"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} columns={10}>
                        <Grid item xs={10} lg={5}>
                            <RecentBillsCard
                                title="Bills"
                                resource="documents"
                                slug="Bills"
                                numberOfItems={5}
                            />
                        </Grid>
                        <Grid item xs={10} lg={5}>
                            <RecentNotificationsCard
                                title="Notifications"
                                resource="notifications"
                                slug="Notifications"
                                numberOfItems={3}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} columns={10}>
                        <Grid item xs={10}>
                            <AccountDataChartCard
                                title="Accounts Data Chart"
                                numberOfItems={6}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};
