import React from "react";
import {
  EditButton,
} from "@refinedev/mui";
import {
  IResourceComponentsProps,
  useList,
} from "@refinedev/core";
import { Card, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'; // Grid version 1
import LocationCityIcon from '@mui/icons-material/LocationCity';
import {
  TextFieldComponent as TextField,
} from "@refinedev/mui";
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

interface DashboardStatCardProps {
  title?: any;
  value?: any;
  icon?: any;
}

export const LocationCard = ({ title, icon }: DashboardStatCardProps) => {
  return (
      <Card component="div" sx={{ m: 2, pt: 2 }} >
        <CardHeader
        avatar={icon}
        action={
          <EditButton hideText/>
        }
        title={title}
        />
        <CardContent>
          <Divider />
          <TextField fontWeight="bold" value="Canada" />
       </CardContent>

  </Card>
  );
};

interface IProduct {
  id: number;
  Name: string;
}

export const LocationsTableView: React.FC<IResourceComponentsProps> = () => {
  const { data } = useList<IProduct>({
    resource: "Clients",
        meta: {
            select: "id,Name"
        },
});

const clients = data?.data?? [];

  return (
    <Box>
    <Grid container>
            {clients.length > 0 ? (
                clients.map((client: IProduct) => (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        xl={3}
                        key={client.id}
                        sx={{ padding: "8px" }}
                    >
                        <LocationCard
                            title={client.Name}
                            icon={<LocationCityIcon fontSize="large"/>}
                        />
                    </Grid>
                ))
            ) : (
                <Grid
                    container
                    justifyContent="center"
                    padding={3}
                >
                    <TextField value="Empty"/>
                </Grid>
            )}
    </Grid>
    </Box>
  );
};