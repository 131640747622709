import { useCallback } from 'react';
import { ILocalStorageProfile } from 'types/IUserProfile';

const useUpdateProfileInLocalStorage = () => {
  const updateAvatarUrl = useCallback((newAvatarUrl: string) => {
    const profileKey = 'profile';
    let profile = localStorage.getItem(profileKey);

    if (profile) {
      // Parse the JSON string to an object
      const profileObject: ILocalStorageProfile = JSON.parse(profile);

      // Update the avatar_url value
      if (profileObject.app_metadata) {
        profileObject.app_metadata.avatar_url = newAvatarUrl;
      }

      // Convert the object back to a JSON string
      const updatedProfile = JSON.stringify(profileObject);

      // Save the updated object back to local storage
      localStorage.setItem(profileKey, updatedProfile);
    } else {
      console.error('Profile not found in local storage');
    }
  }, []);

  return { updateAvatarUrl };
};

export default useUpdateProfileInLocalStorage;