import React from "react";
import { useDataGrid, EditButton, ShowButton, List } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    IResourceComponentsProps,
    useTranslate,
    useMany,
} from "@refinedev/core";

import { ChipField } from "../../components/ChipField";
import { CreateButton } from "components/buttons/create";
import { TablePaginationActions } from "components/TablePaginationActions";

const roleOptions = [
    { value: "user", color: "#58aa02" },
    { value: "manager", color: "#0091ff" },
    { value: "admin", color: "#ff4c51" },
];

export const ProfilesList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { dataGridProps } = useDataGrid({
        meta: {
            select: "id,username,full_name,job_title,role,tenant_id", //AccountsCount
        },
    });

    const { data: tenantsData } = useMany({
        resource: "tenants",
        ids: dataGridProps?.rows?.map((item: any) => item?.tenant_id) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
        meta: {
            select: "id,Name",
        },
    });

    const columnVisible = {
        id: false,
    };

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("Profiles.fields.id"),
                type: "number",
                minWidth: 50,
            },
            {
                field: "username",
                flex: 1,
                headerName: translate("Profiles.fields.Username"),
                minWidth: 200,
            },
            {
                field: "tenant_id",
                flex: 1,
                headerName: translate("Profiles.fields.Tenant"),
                minWidth: 150,
                renderCell: function render({ value }) {
                    return (
                        tenantsData?.data.find((item: any) => item.id === value)
                            ?.Name ?? ""
                    );
                },
            },
            {
                field: "role",
                flex: 1,
                headerName: translate("Profiles.fields.Role"),
                minWidth: 100,
                renderCell: function render({ value }) {
                    return (
                        <ChipField selected={[value]} options={roleOptions} />
                    );
                },
            },
            {
                field: "full_name",
                flex: 1,
                headerName: translate("Profiles.fields.FullName"),
                minWidth: 200,
            },
            {
                field: "job_title",
                flex: 1,
                headerName: translate("Profiles.fields.JobTitle"),
                minWidth: 200,
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row.id} />
                            <ShowButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
                disableColumnMenu: true,
            },
        ],
        [translate, tenantsData?.data]
    );

    return (
        <List
        headerButtons={[
            <CreateButton
                key={"createButton"}
                sx={{ padding: ".25rem 1.5rem", minwidth: "7.5rem", textTransform: "capitalize" }}
            >
                Add Profile
            </CreateButton>,
        ]}
            wrapperProps={{
                className: "profiles",
            }}
        >
            <DataGrid
                {...dataGridProps}
                columns={columns}
                columnVisibilityModel={columnVisible}
                autoHeight
                slotProps={{
                    pagination: {
                        ActionsComponent: TablePaginationActions
                    }
                }}
            />
        </List>
    );
};
