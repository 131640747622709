import React from "react";

import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { ChipField } from "./ChipField";
// import { vendorTypes } from "../pages/Vendors/config";

interface Props {
  id?: any;
  name?: any;
  label?: any;
  control?: any;
  defaultValue?: string;
  errors?: any;
  options?: any;
  variant?: any;
  margin?: any;
  // children,
  props?: any;
  style?: any;
  sx?: any;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelectField = ({
  name,
  label,
  control,
  defaultValue,
  errors,
  options,
  // children,
  ...props
}: Props) => {
  const labelId = `${name}-label`;
  return (
    <FormControl {...props}>
      <InputLabel id={labelId}>{name}</InputLabel>
      <Controller
        control={control}
        defaultValue={defaultValue}
        rules={{ required: "This field is required" }}
        name={name}
        render={({ field }) => {
          const myvalue = field?.value ? field.value.split(",") : [];
          return (
            <Select
              {...field}
              labelId={labelId}
              id={name}
              multiple
              fullWidth
              label={name}
              name={name}
              error={!!(errors as any)[name]}
              // helperText={
              //     (errors as any)[name]?.message
              // }
              value={myvalue}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                field.onChange(value.join(","));
                console.log("VALUE CHANGED TO", value);
              }}
              renderValue={(selected) => (
                <ChipField selected={selected} options={options} />
              )}
              MenuProps={MenuProps}
            >
              {options.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.value}
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
    </FormControl>
  );
};

export default MultipleSelectField;
