import { Link } from "react-router-dom";
import { useGetIdentity, useList, useTranslate } from "@refinedev/core";

import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Grid,
    Typography,
} from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreVert";

import dayjs from "dayjs";

// Types
import IUser from "types/IUser";
import { useContext } from "react";
import { ColorModeContext } from "contexts/ColorModeContext";

interface IUseList {
    id: number;
    Name: string;
}

interface ResourceCardProps {
    resource: string;
    title?: string | null;
    icon?: any;
    iconBackgroundColor?: string;
    slug?: string;
}

export const ResourceCard = ({
    resource,
    title,
    icon,
    iconBackgroundColor,
    slug,
}: ResourceCardProps) => {
    const { mode } = useContext(ColorModeContext);
    const translate = useTranslate();
    const { data: userIdentity } = useGetIdentity<IUser>();
    const startDate = userIdentity?.last_sign_in_at
        ? dayjs(userIdentity?.last_sign_in_at)
        : dayjs();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data, isLoading, isError } = useList<IUseList>({
        resource: resource,
    });

    const {
        data: dataSinceDate,
        // isLoading: isLoadingDataSinceDate,
        // isError: isErrorDataSinceDate,
    } = useList<IUseList>({
        resource: resource,
        filters: [
            {
                field: "created_at",
                operator: "gt",
                value: startDate, // if, for example, a specific format is needed, use: String(startDate.format("YYYY-MM-DDTHH:mm:ss"))
            },
        ],
    });

    const total = data?.total;
    const totalSinceDate = dataSinceDate?.total;

    return (
        <Card
            sx={{
                padding: "2px",
                borderRadius: "10px",
                backgroundColor: mode === "dark" ? "#212133" : "#fff",
                boxSizing: "border-box",
                boxShadow: "0px 10px 15px 0px rgba(0, 0, 0, 0.15)",
                m: "1rem 0",
            }}
        >
            <CardActionArea component={Link} to={`/${slug}`}>
                {" "}
                <CardHeader
                    sx={{
                        padding: "5px",
                    }}
                    avatar={
                        <Box
                            sx={{
                                marginLeft: "-.5rem",
                                marginTop: "-.5rem",
                                position: "relative",
                                width: "3.5rem",
                                height: "3.5rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                "&::before": {
                                    content: '""',
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                    opacity: mode === "dark" ? "0.35" : "0.20",
                                    padding: ".125rem",
                                    boxSizing: "border-box",
                                    borderBottomRightRadius: ".625rem",
                                    backgroundColor: iconBackgroundColor,
                                },
                            }}
                        >
                            {icon}
                        </Box>
                    }
                    // action={
                    //     <IconButton aria-label="settings">
                    //         <MoreVertIcon
                    //             style={{
                    //                 color: mode === "dark" ? "#fff" : "#212121",
                    //             }}
                    //         />
                    //     </IconButton>
                    // }
                    // title="Title"
                    // subheader="Subtitle"
                />
                <CardContent>
                    <Box display="flex">
                        <Box
                            sx={{
                                flexGrow: 1,
                            }}
                        >
                            <Grid container spacing={1} direction="column">
                                    <Grid item>
                                        <Typography
                                            variant="h5"
                                            style={{
                                                lineHeight: 1,
                                                fontSize: "1.125rem",
                                                paddingTop: 0,
                                            }}
                                            color={
                                                mode === "dark"
                                                    ? "rgba(255, 255, 255, 0.8)"
                                                    : "#212121"
                                            }
                                        >
                                            {title}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        style={{
                                            margin: ".25rem",
                                            paddingTop: ".5rem",
                                        }}
                                    >
                                        <Typography fontSize={"1.25rem"}>
                                            {total}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        style={{
                                            margin: ".25rem",
                                            paddingTop: 0,
                                        }}
                                    >
                                        <Typography
                                            component="span"
                                            style={{
                                                color: "#56ca00",
                                                fontSize: ".875rem",
                                            }}
                                        >
                                            {totalSinceDate}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            component="span"
                                            style={{
                                                marginLeft: ".875rem",
                                                color:
                                                    mode === "dark"
                                                        ? "rgba(255, 255, 255, 0.35)"
                                                        : "rgba(33, 33, 33, 0.35)",
                                                fontSize: ".875rem",
                                            }}
                                        >
                                            {translate("dashboard.card.New")}
                                        </Typography>
                                    </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};
