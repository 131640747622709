import React, { useContext, useEffect, useState } from "react";
import DarkModeOutlined from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlined from "@mui/icons-material/LightModeOutlined";

import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
    BaseRecord,
    useGetIdentity,
    useGetLocale,
    useOne,
    useSetLocale,
} from "@refinedev/core";
import { RefineThemedLayoutV2HeaderProps } from "@refinedev/mui";
import { HamburgerMenu } from "components/themedLayout/hamburgerMenu";
import i18n from "i18n";
import { ColorModeContext } from "../../contexts/ColorModeContext";
import { useSharedState } from "contexts/SharedStateContext";
import CircularProgress from "@mui/material/CircularProgress";
import { Badge, Box, Menu, Tooltip } from "@mui/material";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LanguageIcon from "@mui/icons-material/Language";
import UserProfile from "components/UserProfile";
import { NewNotificationsPopover } from "./NewNotificationsPopover";

// Types
import IUser from "types/IUser";

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
    sticky = true,
}) => {
    const { mode, setMode } = useContext(ColorModeContext);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sharedState, setSharedState } = useSharedState();

    const { data: user } = useGetIdentity<IUser>();

    const [avatarUrl, setAvatarUrl] = useState<string | undefined>();

    const identity = JSON.parse(localStorage.getItem("profile") as string);

    const {
        data: tenant,
        isLoading,
    }: { data?: BaseRecord; isLoading: boolean } = useOne({
        resource: "tenants",
        id: identity?.tenant_id,
    });

    useEffect(() => {
        if (sharedState.updatedAvatarUrl) {
            setAvatarUrl(sharedState.updatedAvatarUrl);
        } else {
            if (user?.avatar_url) setAvatarUrl(user?.avatar_url);
        }
    }, [sharedState.updatedAvatarUrl, user?.avatar_url]);

    // const { data: perm } = usePermissions();

    const changeLanguage = useSetLocale();
    const locale = useGetLocale();
    const currentLocale = locale();

    // console.log(user?.id, user?.name, user.avatar)
    // console.log(perm)

    const [anchorElLanguage, setAnchorElLanguage] =
        React.useState<null | HTMLElement>(null);

    const handleOpenLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElLanguage(event.currentTarget);
    };
    const handleCloseLanguageMenu = () => {
        setAnchorElLanguage(null);
    };
    const handleOpenUserProfileDrawer = (
        event: React.MouseEvent<HTMLElement>
    ) => {
        setSharedState((prevState) => ({
            ...prevState,
            userProfileDrawerOpen: !prevState.userProfileDrawerOpen,
        }));
    };
    const handleNotificationsOpen = (event: React.MouseEvent<HTMLElement>) => {
        setSharedState((prevState) => ({
            ...prevState,
            notificationsPopoverOpen: !prevState.notificationsPopoverOpen,
            anchorElNotifications: event.currentTarget,
        }));
    };

    return (
        <AppBar
            position={sticky ? "sticky" : "relative"}
            sx={{
                borderRadius: "0 0 10px 10px",
                backgroundImage: "none",
                boxShadow: "none",
                backgroundColor: mode === "dark" ? "#212133" : "#fff",
                width: "auto",
            }}
        >
            <Toolbar>
                <Stack direction="row" width="100%" alignItems="center">
                    <HamburgerMenu />
                    <>
                        <HomeWorkIcon
                            sx={{
                                color: mode === "dark" ? "#fff" : "#000",
                                opacity: 0.35,
                                mr: ".75rem",
                            }}
                        />
                        {isLoading ? (
                            <CircularProgress size={20} />
                        ) : (
                            <Typography
                                sx={{
                                    display: {
                                        xs: "none",
                                        sm: "inline-block",
                                        minWidth: "fit-content",
                                        color:
                                            mode === "dark"
                                                ? "#fff"
                                                : "#212121",
                                    },
                                }}
                            >
                                {tenant?.data?.Name}
                            </Typography>
                        )}
                    </>
                    <Stack
                        direction="row"
                        width="100%"
                        justifyContent="flex-end"
                        alignItems="center"
                        gap=".125rem"
                    >
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Select a language">
                                <IconButton
                                    color="inherit"
                                    onClick={handleOpenLanguageMenu}
                                >
                                    <LanguageIcon
                                        sx={{
                                            color:
                                                mode === "dark"
                                                    ? "#fff"
                                                    : "#212121",
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: "45px" }}
                                id="menu-appbar"
                                anchorEl={anchorElLanguage}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorElLanguage)}
                                onClose={handleCloseLanguageMenu}
                            >
                                {[...((i18n as any).languages ?? [])]
                                    .sort()
                                    .map((lang: string) => (
                                        <MenuItem
                                            selected={currentLocale === lang}
                                            key={lang}
                                            defaultValue={lang}
                                            onClick={() => {
                                                changeLanguage(lang);
                                            }}
                                            value={lang}
                                        >
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Typography
                                                    sx={{
                                                        color:
                                                            mode === "dark"
                                                                ? "#fff"
                                                                : "#212121",
                                                    }}
                                                >
                                                    {lang === "en"
                                                        ? "English"
                                                        : "French"}
                                                </Typography>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                            </Menu>
                        </Box>

                        <IconButton
                            color="inherit"
                            onClick={() => {
                                setMode();
                            }}
                        >
                            {mode === "dark" ? (
                                <LightModeOutlined
                                    sx={{
                                        color:
                                            mode === "dark"
                                                ? "#fff"
                                                : "#212121",
                                    }}
                                />
                            ) : (
                                <DarkModeOutlined
                                    sx={{
                                        color:
                                            mode === "dark"
                                                ? "#fff"
                                                : "#212121",
                                    }}
                                />
                            )}
                        </IconButton>

                        <IconButton
                            aria-label="show 17 new notifications"
                            color="inherit"
                            style={{ marginRight: "1rem" }}
                            onClick={handleNotificationsOpen}
                        >
                            <Badge badgeContent={17} color="error">
                                <NotificationsIcon
                                    sx={{
                                        color:
                                            mode === "dark"
                                                ? "#fff"
                                                : "#212121",
                                    }}
                                />
                            </Badge>
                        </IconButton>

                        <NewNotificationsPopover />

                        {(user?.avatar_url || user?.full_name) && (
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open settings">
                                    <IconButton
                                        onClick={handleOpenUserProfileDrawer}
                                        sx={{ p: 0 }}
                                    >
                                        <Avatar
                                            src={
                                                "https://portal.compareabill.ai/storage/v1/object/public/avatars/" +
                                                avatarUrl
                                            }
                                            alt={user?.full_name}
                                            sx={{
                                                textDecoration: "none",
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <UserProfile />
                            </Box>
                        )}
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};
