import {
    useShow,
    IResourceComponentsProps,
    useTranslate,
} from "@refinedev/core";
import { Show, TextFieldComponent as TextField } from "@refinedev/mui";
import { Typography, Box, Grid, styled, Paper, useTheme } from "@mui/material";
import JsonFormatter from "react-json-formatter";

import { DownloadUrlField } from "components/DownloadUrlField";
import PdfPreview from "components/PdfPreview";
import { ListButton } from "components/buttons/list";
import LoadingIndicator from "components/LoadingIndicator";

export const BillsShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const theme = useTheme();
    const colorMode = theme.palette.mode;

    const { queryResult } = useShow({
        meta: {
            select: "id,Reference,accounts(Name),Docdate,Documents,Metadata",
        },
    });
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const docs = record ? JSON.parse(record?.Documents) : []; // Parse the billData JSON data.
    const docTitle = docs?.[0]?.title; // Extract the title

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#212133" : "#fff",
        backgroundImage: "none",
        boxShadow: "none",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: "left",
    }));

    const jsonStyle = {
        propertyStyle: { color: "#ff2717", fontWeight: "bold" },
        stringStyle: { color: "#007a03" },
        numberStyle: { color: "orange" },
    };

    return (
        <Show
            isLoading={isLoading}
            headerButtons={[
                <ListButton
                    key={"list"}
                    sx={{
                        padding: ".25rem 1.6875rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                >
                    View All Bills
                </ListButton>,
            ]}
            wrapperProps={{
                className: "bills",
            }}
        >
            <Box display="flex">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={3}>
                        <Grid
                            container
                            item
                            spacing={2}
                            sm={12}
                            lg={6}
                            direction="column"
                        >
                            <Grid
                                item
                                container
                                sx={{
                                    justifyItems: "flex-start",
                                    alignItems: "center",
                                    wordBreak: "break-word",
                                }}
                            >
                                <Grid item xs={12} sm={3}>
                                    <Item>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                        >
                                            {translate(
                                                "Bills.fields.Reference"
                                            )}
                                        </Typography>
                                    </Item>
                                </Grid>
                                <Grid item>
                                    <Item>
                                        <TextField value={record?.Reference} />
                                    </Item>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={3}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Bills.fields.Accounts"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField
                                                value={record?.accounts?.Name}
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={3}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Bills.fields.Docdate"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField
                                                value={record?.Docdate}
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12}>
                                        <Grid item xs={12}>
                                            <Item>
                                                <Typography
                                                    variant="body1"
                                                    fontWeight="bold"
                                                >
                                                    {translate(
                                                        "Bills.fields.Metadata"
                                                    )}
                                                </Typography>
                                            </Item>
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                padding: "15px",
                                                borderRadius: "4px",
                                                border:
                                                    colorMode === "dark"
                                                        ? "1px solid rgba(255, 255, 255, 0.1)"
                                                        : "1px solid rgba(0, 0, 0, 0.2)",
                                                backgroundColor:
                                                    colorMode === "dark"
                                                        ? "#171724"
                                                        : "#f2f2f2",
                                                boxSizing: "border-box",
                                                color: "#333333",
                                                lineHeight: "normal",
                                                letterSpacing: "0.3px",
                                            }}
                                        >
                                            <Item
                                                style={{
                                                    backgroundColor:
                                                        colorMode === "dark"
                                                            ? "#171724"
                                                            : "#f2f2f2",
                                                }}
                                            >
                                                {record ? (
                                                    JSON.stringify(
                                                        record?.Metadata
                                                    ) !== "{}" ? (
                                                        <JsonFormatter
                                                            json={
                                                                record?.Metadata
                                                            }
                                                            tabWith={4}
                                                            jsonStyle={
                                                                jsonStyle
                                                            }
                                                        />
                                                    ) : (
                                                        <Typography
                                                            variant="body1"
                                                            fontWeight="bold"
                                                        >
                                                            {translate(
                                                                "Bills.fields.MetadataNotAvailable"
                                                            )}
                                                        </Typography>
                                                    )
                                                ) : (
                                                    <LoadingIndicator />
                                                )}
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            spacing={2}
                            sm={12}
                            lg={6}
                            direction="column"
                        >
                            <Grid
                                item
                                container
                                sx={{
                                    justifyItems: "flex-start",
                                    alignItems: "center",
                                    wordBreak: "break-word",
                                }}
                            >
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={3}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Bills.fields.Document"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField value={docTitle} />
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12}>
                                        <Item>
                                            {record?.Documents ? (
                                                <DownloadUrlField
                                                    billData={record?.Documents}
                                                    button
                                                    sx={{
                                                        padding: ".2rem 1.5rem",
                                                        minwidth: "7.5rem",
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                />
                                            ) : (
                                                "None"
                                            )}
                                        </Item>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                        mt: 2,
                                    }}
                                >
                                    <Grid item xs={12}>
                                        {record?.Documents && (
                                            <>
                                                <Grid item xs={12}>
                                                    <Item>
                                                        <Typography
                                                            variant="body1"
                                                            fontWeight="bold"
                                                        >
                                                            {translate(
                                                                "Bills.fields.DocumentPreview"
                                                            )}
                                                        </Typography>
                                                    </Item>
                                                </Grid>
                                                <Grid
                                                    item
                                                    sx={{
                                                        padding: "15px",
                                                        borderRadius: "4px",
                                                        border:
                                                            colorMode === "dark"
                                                                ? "1px solid rgba(255, 255, 255, 0.1)"
                                                                : "1px solid rgba(0, 0, 0, 0.2)",
                                                        backgroundColor:
                                                            colorMode === "dark"
                                                                ? "#171724"
                                                                : "#f2f2f2",
                                                        boxSizing: "border-box",
                                                        color: "#333333",
                                                        lineHeight: "normal",
                                                        letterSpacing: "0.3px",
                                                    }}
                                                >
                                                    <Item>
                                                        <PdfPreview
                                                            billData={
                                                                record?.Documents
                                                            }
                                                        />
                                                    </Item>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Show>
    );
};
