import * as React from "react";

function LocationAway(props: React.SVGProps<SVGSVGElement>) {
    const color = props.color || "#32c5ff";
    return (
        <svg width="1.25rem" height="1.125rem" fill={color} {...props}>
            <path d="M16.612 13.35c.842.233 1.646.575 2.413 1.025.3.183.538.43.713.738.175.308.262.637.262.987V18H8v-1.9c0-.35.088-.68.262-.988.175-.308.413-.554.713-.737.767-.45 1.57-.792 2.413-1.025A9.734 9.734 0 0114 13c.9 0 1.77.117 2.612.35zm-.487-6.475C16.708 7.458 17 8.167 17 9s-.292 1.542-.875 2.125A2.893 2.893 0 0114 12a2.893 2.893 0 01-2.125-.875A2.893 2.893 0 0111 9c0-.833.292-1.542.875-2.125A2.893 2.893 0 0114 6c.833 0 1.542.292 2.125.875zM8 2.5L2 7v9h4v2H0V6l8-6 5.375 4.05a4.85 4.85 0 00-1.175.287c-.367.142-.717.33-1.05.563L8 2.5zM17.85 16a7.434 7.434 0 00-1.85-.75 8.02 8.02 0 00-2-.25 8.02 8.02 0 00-2 .25 7.434 7.434 0 00-1.85.75h7.7zM13 9c0 .283.096.52.287.712.192.192.43.288.713.288s.52-.096.712-.288A.968.968 0 0015 9a.968.968 0 00-.288-.713A.968.968 0 0014 8a.968.968 0 00-.713.287A.968.968 0 0013 9z" />
        </svg>
    );
}

const LocationAwayIcon = React.memo(LocationAway);
export default LocationAwayIcon;
