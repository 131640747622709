import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";

// Types
import { useContext, useState } from "react";
import { ColorModeContext } from "contexts/ColorModeContext";
import LoadingIndicator from "components/LoadingIndicator";
import InvoiceAccountsChart from "components/charts/InvoiceAccountsChart";
import AccountsSelectDropdown from "components/AccountsSelectDropdown";

interface AccountDataChartCardProps {
    title?: string | null;
    numberOfItems?: number;
}

export const AccountDataChartCard = ({
    title,
    numberOfItems,
}: AccountDataChartCardProps) => {
    const { mode } = useContext(ColorModeContext);
    const [selectedId, setSelectedId] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const handleSelectionChange = (id: string) => {
        setSelectedId(id);
    };

    const handleLoadingChange = (isLoading: boolean) => {
        setLoading(isLoading);
    };

    return (
        <Card
            sx={{
                padding: "2px",
                borderRadius: "10px",
                backgroundColor: mode === "dark" ? "#212133" : "#fff",
                boxSizing: "border-box",
                boxShadow: "0px 10px 15px 0px rgba(0, 0, 0, 0.15)",
                m: "1rem 0",
            }}
        >
            {" "}
            <CardHeader
                sx={{
                    padding: "15px",
                    "& .MuiCardHeader-action": {
                        m: "0 0 0 0",
                    },
                }}
                // title={title}
                subheader={title}
                subheaderTypographyProps={{
                    color:
                        mode === "dark"
                            ? "rgba(255, 255, 255, 0.8)"
                            : "#333333",
                    fontSize: "1.125rem",
                }}
                action={
                    <AccountsSelectDropdown
                        onSelectChange={handleSelectionChange}
                        onLoadingChange={handleLoadingChange}
                    />
                }
            />
            {loading ? (
                <LoadingIndicator size={50} />
            ) : (
                <CardContent>
                    <Box display="flex">
                        <Box
                            sx={{
                                flexGrow: 1,
                            }}
                        >
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                style={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    margin: "1.25rem 0",
                                    width: "100%",
                                }}
                            >
                                {selectedId ? <InvoiceAccountsChart
                                    accountsId={selectedId}
                                    numberOfBars={numberOfItems}
                                /> : <div>No accounts have been found.</div>}
                            </Grid>
                        </Box>
                    </Box>
                </CardContent>
            )}
        </Card>
    );
};
