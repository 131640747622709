import React, { useEffect, useState } from "react";
import { useDataGrid, EditButton, ShowButton, List } from "@refinedev/mui";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { CreateButton } from "components/buttons/create";
import { ExportButton } from "components/buttons/export";
import { TablePaginationActions } from "components/TablePaginationActions";

export const ClientsList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState({});

    useEffect(() => {
        // Update filters based on search text
        if (searchText !== "") {
            setFilters({
                permanent: [
                    { field: "Name", operator: "contains", value: searchText },
                ],
            });
        } else {
            setFilters({});
        }
    }, [searchText]);

    const { dataGridProps } = useDataGrid({
        filters: filters,
        meta: {
            select: "id,Name,ForwardTo,logins(id)",
        },
    });

    const columnVisible = {
        id: false,
    };

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("Clients.fields.id"),
                type: "number",
                minWidth: 50,
                show: false,
            },
            {
                field: "Name",
                flex: 1,
                headerName: translate("Clients.fields.Name"),
                minWidth: 200,
            },
            {
                field: "ForwardTo",
                flex: 1,
                headerName: translate("Clients.fields.ForwardTo"),
                minWidth: 200,
            },
            {
                field: "logins",
                headerName: translate("Clients.fields.Logins"),
                sortable: false,
                renderCell: function render({ row }) {
                    return <div>{row.logins.length}</div>;
                },
                align: "center",
                headerAlign: "center",
                minWidth: 50,
                disableColumnMenu: true,
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row.id} />
                            <ShowButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
                disableColumnMenu: true,
            },
        ],
        [translate]
    );

    const handleSearch = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setSearchText(event.target.value);
    };

    return (
        <Box>
            <List
                headerButtons={[
                    <ExportButton
                        key={"exportButton"}
                        sx={{
                            padding: ".2rem 1.5rem",
                            minwidth: "7.5rem",
                            textTransform: "capitalize",
                        }}
                    />,
                    <CreateButton
                        key={"createButton"}
                        sx={{
                            padding: ".25rem 1.5rem",
                            minwidth: "7.5rem",
                            textTransform: "capitalize",
                        }}
                    >
                        Add Client
                    </CreateButton>,
                ]}
                wrapperProps={{
                    className: "clients",
                }}
            >
                <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchText}
                    onChange={handleSearch}
                    style={{ margin: "-20px 10px 10px 0", width: "16rem" }}
                />
                <DataGrid
                    {...dataGridProps}
                    columns={columns}
                    columnVisibilityModel={columnVisible}
                    autoHeight
                    slotProps={{
                        pagination: {
                            ActionsComponent: TablePaginationActions
                        }
                    }}
                />
            </List>
        </Box>
    );
};
