import { useBack, useTranslate } from "@refinedev/core";
import { SaveButtonProps } from "@refinedev/mui";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

export const BackButton: React.FC<SaveButtonProps> = ({ ...rest }) => {
    const goBack = useBack();
    const translate = useTranslate();

    const { sx } = rest;

    return (
        <Tooltip title={translate("buttons.goBack", "Go back")}>
            <IconButton color="inherit" onClick={goBack} style={{ color: "#1f7b7b" }}>
                <ChevronLeftIcon sx={{ minWidth: 0, ...sx }} />
            </IconButton>
        </Tooltip>
    );
};
