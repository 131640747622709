import Button from "@mui/material/Button";
import { useBack, useTranslate } from "@refinedev/core";
import { SaveButtonProps } from "@refinedev/mui";

export const CancelButton: React.FC<SaveButtonProps> = ({
    children,
    ...rest
}) => {
    const translate = useTranslate();
    const goBack = useBack();

    const { sx, ...restProps } = rest;

    return (
        <Button
            variant="outlined"
            onClick={goBack}
            sx={{ minWidth: 0, ...sx }}
            {...restProps}
        >
            {children ?? translate("buttons.cancel", "Cancel")}
        </Button>
    );
};
