import React, { useState, useEffect } from "react";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Typography,
    SelectChangeEvent,
} from "@mui/material";
import { useOne } from "@refinedev/core";

interface Account {
    id: string;
    Name: string;
}

interface Tenant {
    id: string;
    Name: string;
    accounts: Account[];
}

interface AccountsSelectDropdownProps {
    onSelectChange: (id: string) => void;
    onLoadingChange: (isLoading: boolean) => void;
}

const AccountsSelectDropdown: React.FC<AccountsSelectDropdownProps> = ({
    onSelectChange,
    onLoadingChange,
}) => {
    const identity = JSON.parse(localStorage.getItem("profile") as string);
    const { data, isLoading, isError } = useOne<Tenant>({
        resource: "tenants",
        id: identity?.tenant_id,
        meta: {
            select: "id,Name,accounts(id,Name)",
        },
    });

    const [selectedOption, setSelectedOption] = useState<string>("");

    useEffect(() => {
        onLoadingChange(isLoading);
    }, [isLoading, onLoadingChange]);

    useEffect(() => {
        if (
            data?.data?.accounts &&
            data.data.accounts.length > 0 &&
            !selectedOption
        ) {
            const firstAccountId = data.data.accounts[0].id;
            setSelectedOption(firstAccountId);
            onSelectChange(firstAccountId);
        }
    }, [data, onSelectChange, selectedOption]);

    const handleChange = (event: SelectChangeEvent<string>) => {
        const selectedId = event.target.value as string;
        setSelectedOption(selectedId);
        onSelectChange(selectedId);
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    if (isError) {
        return <Typography color="error">Error loading data</Typography>;
    }

    const options: Account[] = data?.data?.accounts || [];

    if (options.length === 0) {
        return <></>;
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="dropdown-label">Select Account</InputLabel>
            <Select
                labelId="dropdown-label"
                id="dropdown"
                value={selectedOption}
                onChange={handleChange}
                label="Select Account"
                style={{ minWidth: "200px" }}
            >
                {options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.Name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default AccountsSelectDropdown;
