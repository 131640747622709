import { useContext, useEffect, useState } from "react";
import {
    Avatar,
    Badge,
    Box,
    Button,
    Divider,
    Drawer,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputAdornment,
    Switch,
    TextField,
    Tooltip,
} from "@mui/material";
import { useGetIdentity, useLogout, useTranslate } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import IUser from "types/IUser";
import IUserProfile from "types/IUserProfile";
import CloseIcon from "@mui/icons-material/Close";
import { ColorModeContext } from "contexts/ColorModeContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSharedState } from "contexts/SharedStateContext";
import { Controller, FieldValues } from "react-hook-form";
import { supabaseClient } from "utility";
import { AvatarUploadPopover } from "./header/AvatarUploadPopover";
import EditIcon from "@mui/icons-material/Edit";

const UserProfile: React.FC = () => {
    const translate = useTranslate();
    const { mutate: logout } = useLogout();
    const { data: userIdentity } = useGetIdentity<IUser>();
    const { mode: colorMode } = useContext(ColorModeContext);
    const { sharedState, setSharedState } = useSharedState();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const {
        refineCore: { formLoading, queryResult, onFinish },
        register,
        control,
        handleSubmit,
        watch,
        formState,
    } = useForm<IUserProfile>({
        refineCoreProps: userIdentity?.id
            ? {
                  resource: "profiles",
                  action: "edit",
                  id: userIdentity?.id,
                  redirect: false,
                  onMutationError: (data) => alert(data?.message),
              }
            : undefined,
    });

    const errors = formState.errors as any;

    const ProfilesData = queryResult?.data?.data;

    const [notifications, setNotifications] = useState(false);

    const avatarUrl = sharedState.updatedAvatarUrl
        ? sharedState.updatedAvatarUrl
        : userIdentity?.avatar_url;

    useEffect(() => {
        if (ProfilesData && ProfilesData?.notifications) {
            setNotifications(ProfilesData.notifications);
        }
    }, [ProfilesData]);

    const user_id = ProfilesData?.id;

    const handleAvatarUploadPopoverOpen = (
        event: React.MouseEvent<HTMLElement>
    ) => {
        setSharedState((prevState) => ({
            ...prevState,
            avatarUploadPopoverOpen: !prevState.avatarUploadPopoverOpen,
            anchorElAvatarUpload: event.currentTarget,
        }));
    };

    const onFinishHandler = (data: FieldValues) => {
        console.log("SUBMITTING DATA", data);

        const identity = JSON.parse(localStorage.getItem("profile") as string);

        console.log("ID is ", user_id);
        if (data.password !== "" && user_id) {
            console.log("Resetting password.");
            supabaseClient.auth.admin.updateUserById(user_id as string, {
                password: data.password,
                app_metadata: {
                    ...identity.app_metadata,
                    tenant_id: data.tenant_id,
                },
            });
        } else if (user_id) {
            supabaseClient.auth.admin.updateUserById(user_id as string, {
                app_metadata: {
                    ...identity.app_metadata,
                    tenant_id: data.tenant_id,
                },
            });
        }

        var profiles_data = { ...data };
        delete profiles_data["password"];
        delete profiles_data["confirmPassword"];

        console.log("Submitting profile", profiles_data);

        onFinish(profiles_data);
    };

    // Watch the password field to compare it with the `confirmPassword` field
    const password = watch("password");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotifications(event.target.checked);
    };

    const handleLogout = () => {
        setSharedState((prevState) => ({
            ...prevState,
            userProfileDrawerOpen: false,
        }));

        logout();
    };

    return (
        <div>
            <Drawer
                anchor="right"
                open={sharedState?.userProfileDrawerOpen ?? false}
                onClose={() =>
                    setSharedState((prevState) => ({
                        ...prevState,
                        userProfileDrawerOpen: false,
                    }))
                }
                hideBackdrop
                sx={{
                    "& .MuiDrawer-paper": {
                        width: 300,
                        overflow: "hidden",
                        transition:
                            "width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                        justifyContent: "space-between",
                        backgroundColor:
                            colorMode === "light" ? "#fff" : "#313142",
                        backgroundImage: "none",
                    },
                }}
            >
                {" "}
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            p: ".375rem 1.25rem",
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            My Profile
                        </Box>
                        <IconButton
                            onClick={() =>
                                setSharedState((prevState) => ({
                                    ...prevState,
                                    userProfileDrawerOpen: false,
                                }))
                            }
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider />
                    <Box
                        component="form"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: { xs: "100%" },
                            p: "1.25rem 1.25rem",
                        }}
                        autoComplete="off"
                    >
                        {(userIdentity?.avatar_url ||
                            userIdentity?.full_name) && (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    my: 1,
                                }}
                            >
                                <Tooltip title="Edit Avatar">
                                    <IconButton
                                        onClick={handleAvatarUploadPopoverOpen}
                                        sx={{ p: 0 }}
                                    >
                                        <Badge
                                            overlap="circular"
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "right",
                                            }}
                                            badgeContent={
                                                <Avatar
                                                    sx={(theme) => ({
                                                        bgcolor: "#000",
                                                        color: "#fff",
                                                        width: 22,
                                                        height: 22,
                                                        border: `2px solid ${theme.palette.background.paper}`,
                                                    })}
                                                >
                                                    <EditIcon
                                                        style={{ fontSize: 14 }}
                                                    />
                                                </Avatar>
                                            }
                                        >
                                            <Avatar
                                                src={
                                                    "https://portal.compareabill.ai/storage/v1/object/public/avatars/" +
                                                    avatarUrl
                                                }
                                                alt={userIdentity?.full_name}
                                                sx={{
                                                    textDecoration: "none",
                                                    backgroundColor:
                                                        "rgba(31, 123, 123, 0.5)",
                                                    color: "#fff",
                                                }}
                                            />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>

                                <AvatarUploadPopover />
                            </Box>
                        )}

                        <TextField
                            {...register("username")}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            type="email"
                            label={translate("Profiles.fields.Username")}
                            name="username"
                            size="small"
                            disabled
                        />

                        <Controller
                            control={control}
                            name="password"
                            rules={{
                                validate: (value) => {
                                    if (value === "") return true; // Allow empty password - because of how the backend is currently setup, this is used in the case when the user does not want to update their password.

                                    if (value.length < 8) {
                                        return translate(
                                            "Profiles.fields.PasswordMinLength"
                                        );
                                    }
                                    if (!/[A-Z]/.test(value)) {
                                        return translate(
                                            "Profiles.fields.PasswordValidateUpperCase"
                                        );
                                    }
                                    if (!/[a-z]/.test(value)) {
                                        return translate(
                                            "Profiles.fields.PasswordValidateLowerCase"
                                        );
                                    }
                                    if (!/[^a-zA-Z0-9]/.test(value)) {
                                        return translate(
                                            "Profiles.fields.PasswordValidateNonAlphaNumeric"
                                        );
                                    }
                                    return true; // Return true if validation passes
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...register("password")}
                                    error={!!(errors as any)?.Password}
                                    helperText={
                                        (errors as any)?.Password?.message
                                    }
                                    margin="normal"
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={
                                                        handleClickShowPassword
                                                    }
                                                    onMouseDown={
                                                        handleMouseDownPassword
                                                    }
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOffIcon />
                                                    ) : (
                                                        <VisibilityIcon />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    label={translate(
                                        "Profiles.fields.Password"
                                    )}
                                />
                            )}
                        />
                        {errors.password && (
                            <span style={{ color: "red" }}>
                                {errors.password.message}
                            </span>
                        )}

                        <TextField
                            {...register("confirmPassword", {
                                validate: (value) =>
                                    value === password ||
                                    translate(
                                        "Profiles.fields.passwordDoNotMatch"
                                    ),
                            })}
                            margin="normal"
                            size="small"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityOffIcon />
                                            ) : (
                                                <VisibilityIcon />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            label={translate("Profiles.fields.confirmPassword")}
                        />
                        {errors.confirmPassword && (
                            <span style={{ color: "red" }}>
                                {errors.confirmPassword.message}
                            </span>
                        )}
                        <TextField
                            {...register("role")}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label={translate("Profiles.fields.Role")}
                            name="role"
                            size="small"
                            disabled
                        />
                        <TextField
                            {...register("full_name")}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label={translate("Profiles.fields.FullName")}
                            name="full_name"
                            size="small"
                        />
                        <TextField
                            {...register("job_title")}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label={translate("Profiles.fields.JobTitle")}
                            name="job_title"
                            size="small"
                        />
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        {...register("notifications")}
                                        checked={notifications}
                                        onChange={handleChange}
                                        inputProps={{
                                            "aria-label": "resolved",
                                        }}
                                    />
                                }
                                label={translate(
                                    "Profiles.fields.Notifications"
                                )}
                            />
                        </FormGroup>
                    </Box>
                </Box>
                <Box>
                    <Divider />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mt: 3,
                            p: "1.25rem 1.25rem",
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                                setSharedState((prevState) => ({
                                    ...prevState,
                                    userProfileDrawerOpen: false,
                                }))
                            }
                            sx={{
                                width: "7.5rem",
                                textTransform: "capitalize",
                            }}
                            size="small"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                width: "7.5rem",
                                textTransform: "capitalize",
                            }}
                            disabled={formLoading}
                            onClick={handleSubmit(onFinishHandler)}
                            size="small"
                        >
                            Update
                        </Button>
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            mt: 3,
                            p: "1.25rem 1.25rem",
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={handleLogout}
                            color={"sunsetOrange"}
                            size="small"
                            sx={{
                                width: "100%",
                                color: "#fff",
                                "&:hover": {
                                    backgroundColor: (theme) =>
                                        theme.palette.sunsetOrangeHover.main,
                                },
                                textTransform: "capitalize",
                            }}
                        >
                            Logout{" "}
                            <LogoutIcon sx={{ ml: 1, fontSize: ".875rem" }} />
                        </Button>
                    </Box>
                </Box>
            </Drawer>
        </div>
    );
};

export default UserProfile;
