import { Box, Divider, Grid, Popover, Typography } from "@mui/material";
import { useContext } from "react";
import { useSharedState } from "contexts/SharedStateContext";
import { ColorModeContext } from "contexts/ColorModeContext";
import AvatarUploadForm from "components/AvatarUploadForm";

export const AvatarUploadPopover = () => {
    const { mode } = useContext(ColorModeContext);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sharedState, setSharedState } = useSharedState();

    const handleAvatarUploadPopoverClose = () => {
        setSharedState((prevState) => ({
            ...prevState,
            anchorElAvatarUpload: null,
        }));
    };

    const handleAvatarUploadPopoverOpen = Boolean(
        sharedState?.anchorElAvatarUpload
    );
    const avatarUploaderId = handleAvatarUploadPopoverOpen
        ? "simple-popover"
        : undefined;

    return (
        <Popover
            id={avatarUploaderId}
            open={handleAvatarUploadPopoverOpen}
            anchorEl={sharedState?.anchorElAvatarUpload}
            onClose={handleAvatarUploadPopoverClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            slotProps={{
                paper: {
                    sx: (theme) => {
                        return {
                            width: "360px",
                            backgroundColor:
                                theme.palette.mode === "light"
                                    ? "#fff"
                                    : "#313142",
                            backgroundImage: "none",
                        };
                    },
                },
            }}
        >
            <Box display="flex" flexDirection="column" maxHeight="486px">
                {/* Header */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: "1rem",
                    }}
                >
                    <Box
                        sx={(theme) => ({
                            display: "flex",
                            alignItems: "center",
                            fontSize: "1rem",
                            fontFamily:
                                '"ProductSans-Bold", "Product Sans Bold", "Product Sans", sans-serif',
                            fontWeight: 700,
                            color:
                                theme.palette.mode === "light"
                                    ? "#212121"
                                    : "rgba(255, 255, 255, 0.8)",
                        })}
                    >
                        Upload Avatar
                    </Box>
                </Box>
                <Divider />

                {/* Scrollable Content */}
                <Box flex={1} overflow="auto">
                    <Box p="0 16px">
                        <Box display="flex">
                            <Box
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                    sx={(theme) => ({
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        margin: "0",
                                        padding: ".875rem 0 .625rem",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        borderBottom:
                                            theme.palette.mode === "light"
                                                ? "1px solid #79797915"
                                                : "1px solid #79797945",
                                    })}
                                >
                                    <Grid
                                        item
                                        style={{
                                            padding: "0 0 0 0",
                                        }}
                                        container
                                        spacing={1}
                                        direction="column"
                                    >
                                        <Grid item>
                                            <Typography
                                                component="div"
                                                style={{
                                                    fontSize: "1rem",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                                color={
                                                    mode === "dark"
                                                        ? "rgba(255, 255, 255, 0.8)"
                                                        : "#212121"
                                                }
                                            >
                                                <AvatarUploadForm />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Popover>
    );
};
