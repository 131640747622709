import React, { useEffect, useState } from "react";
import { Edit } from "@refinedev/mui";
import {
    Box,
    TextField,
    FormGroup,
    FormControlLabel,
    Switch,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import {
    IResourceComponentsProps,
    useTranslate,
    useParsed,
} from "@refinedev/core";

import SelectField from "../../components/SelectField";
import AutoCompleteField from "../../components/AutoCompleteField";
import { FieldValues } from "react-hook-form";

import { supabaseClient } from "utility";

import { roleOptions } from "./config";
import { CancelButton } from "components/buttons/cancel";
import { SaveButton } from "components/buttons/save";
import { BackButton } from "components/buttons/back";
import { ListButton } from "components/buttons/list";

// https://roughlywritten.substack.com/p/supabase-multi-tenancy-simple-and
// https://supabase.com/docs/guides/database/postgres/triggers#example-trigger-function
// https://stackoverflow.com/questions/35568071/postgresql-insert-trigger-to-concatenate

export const ProfilesEdit: React.FC<IResourceComponentsProps> = () => {

    const translate = useTranslate();
    const {
        // saveButtonProps,
        refineCore: { queryResult, onFinish },
        register,
        control,
        handleSubmit,
        formState,
    } = useForm();

    const errors = formState.errors as any;

    const ProfilesData = queryResult?.data?.data;

    const [notifications, setNotifications] = useState(false);

    useEffect(() => {
        if (ProfilesData) {
            setNotifications(ProfilesData?.notifications);
        }
    }, [ProfilesData]);

    const { id: user_id } = useParsed();

    const onFinishHandler = (data: FieldValues) => {
        console.log("SUBMITTING DATA", data);

        const identity = JSON.parse(localStorage.getItem("profile") as string);

        console.log("ID is ", user_id);
        if (user_id) {
            supabaseClient.auth.admin.updateUserById(user_id as string, {
                app_metadata: {
                    ...identity.app_metadata,
                    tenant_id: data.tenant_id,
                },
            });
        }

        var profiles_data = { ...data };

        console.log("Submitting profile", profiles_data);

        onFinish(profiles_data);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotifications(event.target.checked);
    };

    return (
        <Edit
            headerButtons={[
                <ListButton
                    key={"list"}
                    sx={{
                        padding: ".25rem 1.6875rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                >
                    View All Profiles
                </ListButton>,
            ]}
            footerButtons={[
                <CancelButton
                    key={"cancel"}
                    sx={{
                        padding: ".25rem 2.25rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                />,
                <SaveButton
                    key={"save"}
                    sx={{
                        padding: ".25rem 2.25rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                    onClick={handleSubmit(onFinishHandler)}
                >
                    Update
                </SaveButton>,
            ]}
            goBack={<BackButton />}
            wrapperProps={{
                className: "profiles",
            }}
        >
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    {...register("username", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.username}
                    helperText={(errors as any)?.username?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="email"
                    label={translate("Profiles.fields.Username")}
                    name="username"
                    disabled
                />

                <AutoCompleteField
                    id="tenant_id"
                    control={control}
                    resource="tenants"
                    field="tenant_id"
                    name="Name"
                    defaultValue={ProfilesData?.tenant_id}
                    label={translate("Profiles.fields.Tenant")}
                    error={!!(errors as any)?.tenant_id}
                    helperText={(errors as any)?.tenant_id?.message}
                />
                <SelectField
                    id="role"
                    name="role"
                    label="Role"
                    margin="normal"
                    control={control}
                    defaultValue={""}
                    helperText={(errors as any)?.role?.message}
                    error={!!(errors as any)?.role}
                    options={roleOptions}
                />
                <TextField
                    {...register("full_name")}
                    error={!!(errors as any)?.full_name}
                    helperText={(errors as any)?.full_name?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("Profiles.fields.FullName")}
                    name="full_name"
                />
                <TextField
                    {...register("job_title")}
                    error={!!(errors as any)?.job_title}
                    helperText={(errors as any)?.job_title?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("Profiles.fields.JobTitle")}
                    name="job_title"
                />
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                {...register("notifications")}
                                checked={notifications}
                                onChange={handleChange}
                                inputProps={{ "aria-label": "resolved" }}
                            />
                        }
                        label={translate("Profiles.fields.Notifications")}
                    />
                </FormGroup>
            </Box>
        </Edit>
    );
};
