import {
    useShow,
    IResourceComponentsProps,
    useTranslate,
} from "@refinedev/core";
import { Show, TextFieldComponent as TextField } from "@refinedev/mui";
import { Typography, Box, Grid, Paper, styled } from "@mui/material";
import { EditButton } from "components/buttons/edit";
import { BackButton } from "components/buttons/back";
import { ListButton } from "components/buttons/list";

export const ProfilesShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { queryResult } = useShow({
        meta: {
            select: "id,username,tenants(Name),role,full_name,job_title,notifications",
        },
    });
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#212133" : "#fff",
        backgroundImage: "none",
        boxShadow: "none",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: "left",
    }));

    return (
        <Show
            isLoading={isLoading}
            headerButtons={[
                <ListButton
                    key={"list"}
                    sx={{
                        padding: ".25rem 1.6875rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                >
                    View All Profiles
                </ListButton>,
            ]}
            footerButtons={[
                <EditButton
                    key={"edit"}
                    sx={{
                        padding: ".25rem 2.25rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                >
                    Edit
                </EditButton>,
            ]}
            footerButtonProps={{
                sx: { justifyContent: "flex-end", p: "16px", mb: ".625rem" },
            }}
            goBack={<BackButton />}
            wrapperProps={{
                className: "profiles",
            }}
        >
            <Box display="flex">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={3}>
                        <Grid
                            container
                            item
                            spacing={2}
                            xs={12}
                            sm={6}
                            direction="column"
                        >
                            <Grid
                                item
                                container
                                sx={{
                                    justifyItems: "flex-start",
                                    alignItems: "center",
                                    wordBreak: "break-word",
                                }}
                            >
                                <Grid item xs={12} sm={4}>
                                    <Item>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                        >
                                            {translate(
                                                "Profiles.fields.Username"
                                            )}
                                        </Typography>
                                    </Item>
                                </Grid>
                                <Grid item>
                                    <Item>
                                        <TextField value={record?.username} />
                                    </Item>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={4}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Profiles.fields.Tenant"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField
                                                value={record?.tenants?.Name}
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={4}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Profiles.fields.Role"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField value={record?.role} />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            spacing={2}
                            xs={12}
                            sm={6}
                            direction="column"
                        >
                            <Grid
                                item
                                container
                                sx={{
                                    justifyItems: "flex-start",
                                    alignItems: "center",
                                    wordBreak: "break-word",
                                }}
                            >
                                <Grid item xs={12} sm={4}>
                                    <Item>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                        >
                                            {translate(
                                                "Profiles.fields.FullName"
                                            )}
                                        </Typography>
                                    </Item>
                                </Grid>
                                <Grid item>
                                    <Item>
                                        <TextField value={record?.full_name} />
                                    </Item>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={4}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Profiles.fields.JobTitle"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField
                                                value={record?.job_title}
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={4}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Profiles.fields.Notifications"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField
                                                value={
                                                    record?.notifications
                                                        ? "Enabled"
                                                        : "Disabled"
                                                }
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Show>
    );
};
