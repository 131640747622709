// SharedStateContext.tsx
import React, { createContext, useState, useContext, ReactNode } from "react";
import ISharedState from "types/ISharedState";


type ContextType = {
    sharedState: ISharedState;
    setSharedState: React.Dispatch<React.SetStateAction<ISharedState>>;
};

const initialState: ISharedState = {
    userProfileDrawerOpen: false,
    notificationsPopoverOpen: false,
    anchorElNotifications: null,
    avatarUploadPopoverOpen: false,
    anchorElAvatarUpload: null,
    updatedAvatarUrl: null,
    savingsCardPopoverOpen: false,
    anchorElSavingsCard: null,
};

const SharedStateContext = createContext<ContextType>({
    sharedState: initialState,
    setSharedState: () => {},
});

export const SharedStateProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [sharedState, setSharedState] = useState<ISharedState>(initialState);

    return (
        <SharedStateContext.Provider value={{ sharedState, setSharedState }}>
            {children}
        </SharedStateContext.Provider>
    );
};

export const useSharedState = (): ContextType => useContext(SharedStateContext);
