import { memo, useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import Box from "@mui/material/Box";
import { useList } from "@refinedev/core";
import LoadingIndicator from "components/LoadingIndicator";

interface IUseList {
    id: number;
    Docdate: string | null | undefined;
    TotalAmount: number | null | undefined;
    BalanceForward: number | null | undefined;
}

interface ISeriesData {
    label: string;
    data: number[];
    color: string;
    stack: string;
}

interface InvoiceAccountsChartProps {
    accountsId: any;
    numberOfBars?: number;
}

const InvoiceAccountsChart: React.FC<InvoiceAccountsChartProps> = memo(
    ({ accountsId, numberOfBars = 6 }) => {
        const { data, isLoading } = useList<IUseList>({
            resource: "documents",
            pagination: { pageSize: numberOfBars }, // This will set the number of bars shown in the chart.
            sorters: [{ field: "Docdate", order: "desc" }],
            filters: [
                { field: "accounts_id", operator: "eq", value: accountsId },
            ],
        });

        const [chartData, setChartData] = useState<{
            docDateArray: string[];
            series: ISeriesData[];
            maxCombinedAmount: number;
        }>({
            docDateArray: [],
            series: [],
            maxCombinedAmount: 0,
        });

        useEffect(() => {
            if (!isLoading && data?.data) {
                // Filter and map valid data points where TotalAmount is not null
                const validData = data.data
                    .filter((item) => item.TotalAmount !== null)
                    .map((item) => ({
                        date: item.Docdate || "", // Handle null or undefined Docdate
                        amount: item.TotalAmount!,
                        owing: item.BalanceForward ?? 0, // Default to 0 if BalanceForward is null
                    }));

                // Check if there are any valid data points
                if (validData.length === 0) {
                    setChartData({
                        docDateArray: [],
                        series: [],
                        maxCombinedAmount: 0,
                    });
                    return;
                }

                // Process valid data
                const reversedData = validData.reverse();

                const docDateArray = reversedData.map((item) => item.date);
                const totalAmountData = reversedData.map((item) => item.amount);
                const owingAmountData = reversedData.map((item) => item.owing);

                // Calculate max combined amount (TotalAmount + BalanceForward)
                const combinedAmounts = reversedData.map(
                    (item) => item.amount + item.owing
                );
                const maxCombinedAmount = Math.max(...combinedAmounts);

                // Update chart data state
                setChartData({
                    docDateArray,
                    series: [
                        {
                            label: "Amount",
                            data: totalAmountData,
                            color: "#1f7b7b",
                            stack: "total",
                        },
                        {
                            label: "Owing",
                            data: owingAmountData,
                            color: "#ea4236",
                            stack: "total",
                        },
                    ],
                    maxCombinedAmount,
                });
            }
        }, [data, isLoading, numberOfBars, accountsId]);

        return (
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                {isLoading ? (
                    <LoadingIndicator size={50} />
                ) : chartData.docDateArray.length > 0 ? (
                    <BarChart
                        height={500}
                        xAxis={[
                            {
                                label: "Bill Date",
                                scaleType: "band" as const,
                                data: chartData.docDateArray,
                                tickLabelStyle: {
                                    angle: 45,
                                    dominantBaseline: "hanging",
                                    textAnchor: "start",
                                },
                                labelStyle: {
                                    transform: "translateY(50px)",
                                },
                            },
                        ]}
                        yAxis={[{ min: 0, max: chartData.maxCombinedAmount }]}
                        series={chartData.series}
                        margin={{ bottom: 90 }}
                    />
                ) : (
                    <p>No data available.</p>
                )}
            </Box>
        );
    }
);

export default InvoiceAccountsChart;
