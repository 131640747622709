import React, { useEffect, useState } from "react";
import { useDataGrid, EditButton, ShowButton, List } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    IResourceComponentsProps,
    useTranslate,
    useMany,
} from "@refinedev/core";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ChipField } from "../../components/ChipField";
import { TablePaginationActions } from "components/TablePaginationActions";

const statusOptions = [
    { value: "Active", color: "#58aa02" },
    { value: "Inactive", color: "#ffab9" },
    { value: "Error", color: "#ef9a9a" },
];

export const AccountsList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState({});

    useEffect(() => {
        // Update filters based on search text
        if (searchText !== "") {
            setFilters({
                permanent: [
                    { field: "Name", operator: "contains", value: searchText },
                ],
            });
        } else {
            setFilters({});
        }
    }, [searchText]);

    const { dataGridProps } = useDataGrid({
        filters: filters,
        meta: {
            select: "id,Name,Status,vendors_id,Last_Checked", //AccountsCount
        },
    });

    const { data: vendorsData, isLoading: vendorsIsLoading } = useMany({
        resource: "vendors",
        ids: dataGridProps?.rows?.map((item: any) => item?.vendors_id) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
        meta: {
            fields: ["id", "Name"],
        },
    });

    const columnVisible = {
        id: false,
    };

    const columns = React.useMemo<GridColDef[]>((): GridColDef[] => {
        const identity = JSON.parse(localStorage.getItem("profile") as string);
        const column_config = [
            {
                field: "id",
                headerName: translate("Accounts.fields.id"),
                type: "number",
                minWidth: 50,
                show: false,
            },
            {
                field: "Name",
                flex: 1,
                headerName: translate("Accounts.fields.Name"),
                minWidth: 200,
            },
            {
                field: "Status",
                flex: 1,
                headerName: translate("Accounts.fields.Status"),
                minWidth: 100,
                renderCell: function render({ value }: { value: any }) {
                    return (
                        <ChipField selected={[value]} options={statusOptions} />
                    );
                },
            },
            {
                field: "vendors_id",
                flex: 1,
                headerName: translate("Accounts.fields.Vendor"),
                minWidth: 150,
                renderCell: function render({ value }: { value: any }) {
                    if (vendorsIsLoading) {
                        return "Loading...";
                    }
                    return (
                        vendorsData?.data.find((item: any) => item.id === value)
                            ?.Name ?? ""
                    );
                },
            },
            {
                field: "Last_Checked",
                flex: 1,
                headerName: translate("Accounts.fields.Last_Checked"),
                minWidth: 200,
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }: { row: any }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row.id} />
                            <ShowButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
                disableColumnMenu: true,
            },
        ];
        return column_config.filter(
            (item: any) =>
                item.field !== "clients_id" ||
                identity.role === "manager" ||
                identity.role === "admin"
        ) as GridColDef[];
    }, [translate, vendorsData?.data, vendorsIsLoading]);

    const handleSearch = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setSearchText(event.target.value);
    };

    return (
        <Box>
            <List
                wrapperProps={{
                    className: "accounts",
                }}
            >
                <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchText}
                    onChange={handleSearch}
                    style={{ margin: "-20px 10px 10px 0", width: "16rem" }}
                />
                <DataGrid
                    {...dataGridProps}
                    columns={columns}
                    columnVisibilityModel={columnVisible}
                    autoHeight
                    slotProps={{
                        pagination: {
                            ActionsComponent: TablePaginationActions
                        }
                    }}
                />
            </List>
        </Box>
    );
};
