import React, { useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import { Button, Box, Typography } from "@mui/material";
import { supabaseClient } from "utility/supabaseClient";
import { useGetIdentity } from "@refinedev/core";
import useUpdateProfileInLocalStorage from "hooks/useUpdateProfileInLocalStorage";

// Types.
import IUser from "types/IUser";
import { useSharedState } from "contexts/SharedStateContext";
import LoadingIndicator from "./LoadingIndicator";

const AvatarUploadForm = () => {
    const { control, handleSubmit, setValue, watch } = useForm();
    const [preview, setPreview] = useState<string | null>(null);
    const { data: userIdentity } = useGetIdentity<IUser>();
    const avatar = watch("avatar");
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sharedState, setSharedState } = useSharedState();
    const { updateAvatarUrl } = useUpdateProfileInLocalStorage();

    const handleAvatarUploadPopoverClose = () => {
        setSharedState((prevState) => ({
            ...prevState,
            anchorElAvatarUpload: null,
        }));
    };

    const onDrop = useCallback(
        (
            acceptedFiles: File[],
            _fileRejections: FileRejection[],
            _event: DropEvent
        ) => {
            setValue("avatar", acceptedFiles);
            setPreview(URL.createObjectURL(acceptedFiles[0]));
        },
        [setValue]
    );

    // Make sure to revoke the object URL to avoid memory leaks, will run on unmount.
    useEffect(() => {
        return () => {
            if (preview) {
                URL.revokeObjectURL(preview);
            }
        };
    }, [preview]);

    const onSubmit = async (data: any) => {
        console.log("Form Data:", { ...data, avatar: data?.avatar });

        try {
            setUploading(true);

            if (!data?.avatar) {
                throw new Error("You must select an image to upload.");
            }

            const file = data?.avatar[0];
            const fileExt = file.name.split(".").pop();
            const fileName = `${Math.random()}.${fileExt}`;
            const filePath = `${fileName}`;

            const { data: storageData, error: uploadError } =
                await supabaseClient.storage
                    .from("avatars")
                    .upload(filePath, file);

            if (uploadError) {
                throw uploadError;
            }

            // Update avatar_url in supabase
            const user_id = userIdentity?.id;
            if (user_id) {
                const { status, error } = await supabaseClient
                    .from("profiles")
                    .update({ avatar_url: storageData?.path })
                    .eq("id", user_id);

                if (status === 204 && error === null) {
                    setUploadSuccess(true);
                    // Update avatar_url in app's shared state.
                    setSharedState((prevState) => ({
                        ...prevState,
                        updatedAvatarUrl: storageData?.path,
                    }));
                    // Update avatar_url in local storage
                    updateAvatarUrl(storageData?.path);
                }
            }
        } catch (error: any) {
            alert(error.message);
        } finally {
            setUploading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {uploadSuccess ? (
                <>
                    <Box sx={{ margin: "3rem 0", textAlign: "center" }}>
                        Upload Successful!
                    </Box>
                    <Box
                        sx={{
                            margin: "1rem 0",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleAvatarUploadPopoverClose}
                            sx={{
                                width: "8rem",
                                textTransform: "capitalize",
                            }}
                            size="small"
                        >
                            Close
                        </Button>
                    </Box>
                </>
            ) : (
                <Box mb={2}>
                    <Controller
                        name="avatar"
                        control={control}
                        render={({ field }) => (
                            <Dropzone
                                onDrop={onDrop}
                                multiple={false}
                                accept={{ "image/png": [], "image/jpeg": [] }}
                            >
                                {({
                                    getRootProps,
                                    getInputProps,
                                    isDragActive,
                                    isFocused,
                                }) => {
                                    return (
                                        <Box
                                            {...getRootProps()}
                                            border="2px dashed #ccc"
                                            p={2}
                                            textAlign="center"
                                            sx={(theme) => ({
                                                borderColor: isDragActive
                                                    ? "#1f7b7b5c"
                                                    : isFocused
                                                      ? theme.palette.mode === "light"
                                                        ? "#1f7b7b5c"
                                                        : "#1f7b7b8f"
                                                      : theme.palette.mode === "light"
                                                        ? "#eeeeee"
                                                        : "#c3c3c34f",
                                                backgroundColor:
                                                    theme.palette.mode ===
                                                    "light"
                                                        ? "#fafafa"
                                                        : "inherit",
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <Typography>
                                                Drag & drop an image here,{" "}
                                                <br />
                                                or click to select one
                                            </Typography>
                                            <Typography
                                                color="textSecondary"
                                                variant="caption"
                                                style={{ fontStyle: "italic" }}
                                            >
                                                (Only *.jpeg and *.png images
                                                <br />
                                                will be accepted)
                                            </Typography>
                                        </Box>
                                    );
                                }}
                            </Dropzone>
                        )}
                    />

                    {uploading ? (
                        <LoadingIndicator
                            size={50}
                            height="20px"
                            topMargin={6}
                        />
                    ) : (
                        preview && (
                            <div
                                style={{
                                    display: "flex",
                                    borderRadius: 2,
                                    border: "1px solid #eaeaea",
                                    margin: "18px auto 10px",
                                    width: 100,
                                    height: 100,
                                    padding: 4,
                                    boxSizing: "border-box",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        minWidth: 0,
                                        overflow: "hidden",
                                    }}
                                >
                                    <img
                                        src={preview}
                                        alt="Preview"
                                        style={{
                                            display: "block",
                                            width: "auto",
                                            height: "100%",
                                        }}
                                        onLoad={() =>
                                            URL.revokeObjectURL(preview)
                                        }
                                    />
                                </div>
                            </div>
                        )
                    )}
                    {!uploading && avatar && avatar.length > 0 && (
                        <Box style={{ textAlign: "center" }}>
                            <Typography>
                                Selected file: {avatar[0].name}
                            </Typography>
                        </Box>
                    )}
                    <Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                mt: 3,
                                p: "1.25rem 1.25rem",
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleAvatarUploadPopoverClose}
                                sx={{
                                    width: "8rem",
                                    textTransform: "capitalize",
                                }}
                                size="small"
                            >
                                Cancel
                            </Button>
                            {
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        width: "8rem",
                                        textTransform: "capitalize",
                                    }}
                                    size="small"
                                    disabled={uploading || !avatar}
                                >
                                    Submit
                                </Button>
                            }
                        </Box>
                    </Box>
                </Box>
            )}
        </form>
    );
};

export default AvatarUploadForm;
