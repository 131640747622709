import {
    useShow,
    IResourceComponentsProps,
    useTranslate,
} from "@refinedev/core";
import { Show, TextFieldComponent as TextField } from "@refinedev/mui";
import { Typography, Box, Grid, styled, Paper } from "@mui/material";
import { EditButton } from "components/buttons/edit";
import { BackButton } from "components/buttons/back";
import { ListButton } from "components/buttons/list";
import { DeleteButton } from "components/buttons/delete";

export const NotificationsShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();

    const { queryResult } = useShow({
        meta: {
            select: "id,title,tenants(Name),created_at,updated_at,details,notes,resolved",
        },
    });
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#212133" : "#fff",
        backgroundImage: "none",
        boxShadow: "none",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: "left",
    }));

    return (
        <Show
            isLoading={isLoading}
            headerButtons={[
                <ListButton
                    key={"list"}
                    sx={{
                        padding: ".25rem 1.6875rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                >
                    View All Notifications
                </ListButton>,
                <DeleteButton
                    key={"delete"}
                    recordItemId={record?.id}
                    confirmOkText="Yes, Delete Notification"
                    sx={{
                        padding: ".25rem 2.25rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                    accessControl={{ enabled: true, hideIfUnauthorized: true }} // For AccessControl settings check the accessControlProvider component.
                />,
            ]}
            footerButtons={[
                <EditButton
                    key={"edit"}
                    sx={{
                        padding: ".25rem 2.25rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                    accessControl={{ enabled: true, hideIfUnauthorized: true }} // For AccessControl settings check the accessControlProvider component.
                >
                    Edit
                </EditButton>,
            ]}
            footerButtonProps={{
                sx: { justifyContent: "flex-end", p: "16px", mb: ".625rem" },
            }}
            goBack={<BackButton />}
            wrapperProps={{
                className: "notifications",
            }}
        >
            <Box display="flex">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={3}>
                        <Grid
                            container
                            item
                            spacing={2}
                            xs={12}
                            sm={6}
                            direction="column"
                        >
                            <Grid
                                item
                                container
                                sx={{
                                    justifyItems: "flex-start",
                                    alignItems: "flex-start",
                                    wordBreak: "break-word",
                                }}
                            >
                                <Grid item xs={12} sm={4}>
                                    <Item>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                        >
                                            {translate(
                                                "Notifications.fields.Title"
                                            )}
                                        </Typography>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Item>
                                        <TextField value={record?.title} />
                                    </Item>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={4}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Notifications.fields.Tenant"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField
                                                value={record?.tenants?.Name}
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={4}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Notifications.fields.CreatedAt"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField
                                                value={
                                                    record?.created_at
                                                        ? new Date(
                                                              record?.created_at
                                                          ).toLocaleString()
                                                        : ""
                                                }
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={4}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Notifications.fields.UpdatedAt"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField
                                                value={
                                                    record?.updated_at
                                                        ? new Date(
                                                              record?.updated_at
                                                          ).toLocaleString()
                                                        : ""
                                                }
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            spacing={2}
                            xs={12}
                            sm={6}
                            direction="column"
                        >
                            <Grid
                                item
                                container
                                sx={{
                                    justifyItems: "flex-start",
                                    alignItems: "flex-start",
                                    wordBreak: "break-word",
                                }}
                            >
                                <Grid item xs={12} sm={4}>
                                    <Item>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                        >
                                            {translate(
                                                "Notifications.fields.Details"
                                            )}
                                        </Typography>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Item>
                                        <TextField value={record?.details} />
                                    </Item>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={4}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Notifications.fields.Notes"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField value={record?.notes} />
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={4}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Notifications.fields.Resolved"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField
                                                value={
                                                    record?.resolved
                                                        ? "Yes"
                                                        : "No"
                                                }
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Show>
    );
};
