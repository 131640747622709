import React, { useEffect, useState } from "react";
import { useDataGrid, ShowButton, List } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    IResourceComponentsProps,
    useTranslate,
    useMany,
} from "@refinedev/core";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { DownloadUrlField } from "components/DownloadUrlField";
import { CreateButton } from "components/buttons/create";
import { TablePaginationActions } from "components/TablePaginationActions";

export const BillsList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState({});

    useEffect(() => {
        // Update filters based on search text
        if (searchText !== "") {
            setFilters({
                permanent: [
                    {
                        field: "Documents",
                        operator: "contains",
                        value: searchText,
                    },
                ],
            });
        } else {
            setFilters({});
        }
    }, [searchText]);

    const { dataGridProps } = useDataGrid({
        filters: filters,
        meta: {
            select: "id,Reference,accounts_id,Docdate,Documents,Metadata",
        },
        sorters: {
            initial: [{ field: "Docdate", order: "desc" }],
        },
    });

    const { data: accountsData } = useMany({
        resource: "accounts",
        ids: dataGridProps?.rows?.map((item: any) => item?.accounts_id) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
        meta: {
            fields: ["id", "Name"],
        },
    });

    const columnVisible = {
        id: false,
    };

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("Bills.fields.id"),
                type: "number",
                minWidth: 50,
            },

            {
                field: "Reference",
                flex: 1,
                headerName: translate("Bills.fields.Reference"),
                minWidth: 150,
            },
            {
                field: "accounts_id",
                flex: 1,
                headerName: translate("Bills.fields.Accounts"),
                minWidth: 150,
                renderCell: function render({ value }) {
                    return (
                        accountsData?.data.find(
                            (item: any) => item.id === value
                        )?.Name ?? ""
                    );
                },
            },
            {
                field: "Docdate",
                flex: 1,
                headerName: translate("Bills.fields.Docdate"),
                minWidth: 100,
            },

            {
                field: "Documents",
                flex: 1,
                headerName: translate("Bills.fields.Documents"),
                minWidth: 300,
                renderCell: function render({ value }) {
                    return (
                        <DownloadUrlField
                            billData={value}
                            sx={{ color: "#1f7b7b", textDecoration: "none" }}
                        />
                    );
                },
                maxWidth: 600,
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            {/* <EditButton hideText recordItemId={row.id} /> */}
                            <ShowButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
                disableColumnMenu: true,
            },
        ],
        [translate, accountsData?.data]
    );

    const handleSearch = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setSearchText(event.target.value);
    };

    return (
        <Box>
            <List
                canCreate={true}
                headerButtons={[
                    <CreateButton
                        key={"createButton"}
                        sx={{ width: "10rem", textTransform: "capitalize" }}
                    >
                        Upload A Bill
                    </CreateButton>,
                ]}
                wrapperProps={{
                    className: "bills",
                }}
            >
                <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchText}
                    onChange={handleSearch}
                    style={{ margin: "-20px 10px 10px 0", width: "16rem" }}
                />
                <DataGrid
                    {...dataGridProps}
                    columns={columns}
                    columnVisibilityModel={columnVisible}
                    autoHeight
                    slotProps={{
                        pagination: {
                            ActionsComponent: TablePaginationActions
                        }
                    }}
                />
            </List>
        </Box>
    );
};
