import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

interface LoadingIndicatorProps {
    size?: number;
    color?:
        | "primary"
        | "inherit"
        | "secondary"
        | "error"
        | "info"
        | "success"
        | "warning";
    topMargin?: number;
    height?: string;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
    size = 100,
    color = "primary",
    topMargin = 0,
    height = "20vh",
}) => {
    return (
        <Stack
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: topMargin,
                height: height,
            }}
        >
            <CircularProgress size={size} color={color} />
        </Stack>
    );
};

export default LoadingIndicator;
