import { useContext, useEffect, useState } from "react";
import {
    // AuthBindings,
    Authenticated,
    Refine,
} from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
    Breadcrumb,
    // ThemedTitleV2,
    ErrorComponent,
    notificationProvider,
    RefineSnackbarProvider,
    ThemedLayoutV2,
} from "@refinedev/mui";

import "./index.css";

import Box from "@mui/material/Box";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import GroupsIcon from "@mui/icons-material/Groups";
import PlaceIcon from "@mui/icons-material/Place";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LocationAwayIcon from "components/svg-icons/LocationAway";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { DashboardPage } from "pages/Dashboard";

import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import routerBindings, {
    CatchAllNavigate,
    DocumentTitleHandler,
    NavigateToResource,
    UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";

import { LocationsList, LocationsCreate } from "pages/Locations";
import {
    ClientsCreate,
    ClientsEdit,
    ClientsList,
    ClientsShow,
} from "pages/Clients";
import {
    VendorsCreate,
    VendorsEdit,
    VendorsList,
    VendorsShow,
} from "pages/Vendors";
import { LoginsCreate, LoginsEdit, LoginsList, LoginsShow } from "pages/Logins";
import {
    AccountsCreate,
    AccountsEdit,
    AccountsList,
    AccountsShow,
} from "pages/Accounts";
import {
    BillsCreate,
    // BillsEdit,
    BillsList,
    BillsShow,
} from "pages/Bills";
import {
    TenantsCreate,
    TenantsEdit,
    TenantsList,
    TenantsShow,
} from "pages/Tenants";
import {
    ProfilesCreate,
    ProfilesEdit,
    ProfilesList,
    ProfilesShow,
} from "pages/Profiles";
import {
    NotificationsEdit,
    NotificationsList,
    NotificationsShow,
} from "pages/Notifications";

// import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Link, Outlet, Route, Routes } from "react-router-dom";
import { Header } from "./components/header";
import { ColorModeContext } from "./contexts/ColorModeContext";

import { dataProvider, liveProvider } from "@refinedev/supabase";
import { supabaseClient } from "./utility";
import authProvider from "./authProvider";
import accessControlProvider from "./accessControlProvider";
import { AuthPage } from "pages/auth";
import { CustomSider } from "components/CustomSider";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import Account from './components/Account'

// const role = 'client'

const App = () => {
    const { t, i18n } = useTranslation();
    const { mode: colorMode } = useContext(ColorModeContext);
    const [horizontalLogoImage, setHorizontalLogoImage] = useState(
        "/images/Compareabill_logo_horizontal_dark_u@2x.png"
    );
    const [noTextLogoImage, setNoTextLogoImage] = useState(
        "/images/Compareabill_logo_horizontal_dark_u@2x.png"
    );

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    useEffect(() => {
        if (colorMode === "light") {
            setHorizontalLogoImage(
                "/images/Compareabill_logo_horizontal_light_u@2x.png"
            );
            setNoTextLogoImage(
                "/images/Compareabill_logo_notext_light_mode@2x.png"
            );
        } else {
            setHorizontalLogoImage(
                "/images/Compareabill_logo_horizontal_dark_u@2x.png"
            );
            setNoTextLogoImage(
                "/images/Compareabill_logo_notext_dark_mode@2x.png"
            );
        }
    }, [colorMode, setHorizontalLogoImage]);

    return (
        <BrowserRouter>
            <RefineKbarProvider>
                <CssBaseline />
                <GlobalStyles
                    styles={{ html: { WebkitFontSmoothing: "auto" } }}
                />
                <RefineSnackbarProvider>
                    <Refine
                        Title={({ collapsed }) => (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "90%",
                                }}
                            >
                                <Link to="/">
                                    {collapsed ? (
                                        <Box
                                            component="img"
                                            sx={{
                                                m: 2,
                                                // ml: 4,
                                                // height: 150,
                                                width: 40,
                                                // maxHeight: { xs: 233, md: 167 },
                                                maxWidth: { xs: 100, md: 100 },
                                            }}
                                            src={noTextLogoImage}
                                            alt="CompareABill"
                                        />
                                    ) : (
                                        <Box
                                            component="img"
                                            sx={{
                                                m: 2,
                                                // ml: 4,
                                                //height: 150,
                                                width: 185,
                                                // maxHeight: { xs: 233, md: 167 },
                                                maxWidth: { xs: 150, md: 185 },
                                            }}
                                            src={horizontalLogoImage}
                                            alt="CompareABill"
                                        />
                                    )}
                                </Link>
                                {/* <img src="/images/ComparABill_logo.png" alt="Logo" /> */}
                                {/* <span>ComparABill</span> */}
                            </div>
                        )}
                        dataProvider={dataProvider(supabaseClient)}
                        liveProvider={liveProvider(supabaseClient)}
                        // dataProvider={{
                        //   default: NocodbProvider("https://comparabill.thinkbeyond.ai/ndb", "7LEfdk4yqor9qnrBDqgD1Fe5fMaOLyq5wegMM8nC")
                        // }}
                        notificationProvider={notificationProvider}
                        routerProvider={routerBindings}
                        authProvider={authProvider}
                        i18nProvider={i18nProvider}
                        accessControlProvider={accessControlProvider}
                        resources={[
                            {
                                name: "Dashboard",
                                list: "/",
                                meta: {
                                    label: "Dashboard",
                                    icon: <DashboardOutlinedIcon />,
                                },
                            },
                            {
                                icon: <GroupsIcon />,
                                name: "clients",
                                list: "/Clients",
                                create: "/Clients/create",
                                edit: "/Clients/edit/:id",
                                show: "/Clients/show/:id",
                                meta: {
                                    // canDelete: true,
                                },
                            },
                            {
                                icon: <PlaceIcon />,
                                name: "Locations",
                                list: "/Locations",
                                create: "/Locations/create",
                                meta: {
                                    // canDelete: true,
                                },
                            },
                            {
                                icon: <BusinessIcon />,
                                name: "vendors",
                                list: "/Vendors",
                                // create: "/Vendors/create", # This removes the create button from the list.
                                edit: "/Vendors/edit/:id",
                                show: "/Vendors/show/:id",
                                meta: {
                                    // canDelete: true,
                                },
                            },
                            {
                                name: "logins",
                                icon: <VpnKeyIcon />,
                                list: "/Logins",
                                create: "/Logins/create",
                                edit: "/Logins/edit/:id",
                                show: "/Logins/show/:id",
                                meta: {
                                    // canDelete: true,
                                },
                            },
                            {
                                name: "accounts",
                                icon: <DescriptionIcon />,
                                list: "/Accounts",
                                // create: "/Accounts/create",
                                edit: "/Accounts/edit/:id",
                                show: "/Accounts/show/:id",
                                meta: {
                                    // canDelete: true,
                                },
                            },
                            {
                                name: "documents",
                                icon: <ReceiptLongIcon />,
                                list: "/Bills",
                                create: "/Bills/create",
                                // edit: "/Bills/edit/:id",
                                show: "/Bills/show/:id",
                                meta: {
                                    label: "Bills",
                                    // canDelete: true,
                                },
                            },
                            {
                                icon: (
                                    <LocationAwayIcon
                                        color="#32c5ff"
                                        className="location-away-icon"
                                    />
                                ),
                                name: "tenants",
                                list: "/Tenants",
                                create: "/Tenants/create",
                                edit: "/Tenants/edit/:id",
                                show: "/Tenants/show/:id",
                                meta: {
                                    // canDelete: true,
                                },
                            },
                            {
                                icon: <AccountCircleOutlinedIcon />,
                                name: "profiles",
                                list: "/Profiles",
                                create: "/Profiles/create",
                                edit: "/Profiles/edit/:id",
                                show: "/Profiles/show/:id",
                                meta: {
                                    // canDelete: true,
                                },
                            },
                            {
                                icon: <NotificationsOutlinedIcon />,
                                name: "notifications",
                                list: "/Notifications",
                                edit: "/Notifications/edit/:id",
                                show: "/Notifications/show/:id",
                                meta: {
                                    // canDelete: true,
                                },
                            },
                        ]}
                        options={{
                            syncWithLocation: true,
                            warnWhenUnsavedChanges: true,
                            // xc-token: "Hct5zk7vEfNnaBV2Pin1Dl94uXDEbf8uccnzPFBx",
                            projectId: "V3auUd-ITkDLi-o1MOto",
                            breadcrumb: (
                                <Breadcrumb
                                    hideIcons
                                    breadcrumbProps={{
                                        separator: <KeyboardArrowRightIcon />,
                                    }}
                                />
                            ),
                        }}
                    >
                        <Routes>
                            <Route
                                element={
                                    <Authenticated
                                        fallback={
                                            <CatchAllNavigate to="/login" />
                                        }
                                    >
                                        <Box className={colorMode}>
                                            <ThemedLayoutV2
                                                Header={() => (
                                                    <Header sticky={true} />
                                                )}
                                                Sider={() => <CustomSider />}
                                            >
                                                <Outlet />
                                            </ThemedLayoutV2>
                                        </Box>
                                    </Authenticated>
                                }
                            >
                                <Route>
                                    <Route index element={<DashboardPage />} />
                                </Route>

                                <Route path="/Clients">
                                    <Route index element={<ClientsList />} />
                                    <Route
                                        path="create"
                                        element={<ClientsCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<ClientsEdit />}
                                    />
                                    <Route
                                        path="show/:id"
                                        element={<ClientsShow />}
                                    />
                                </Route>
                                <Route path="/Vendors">
                                    <Route index element={<VendorsList />} />
                                    <Route
                                        path="create"
                                        element={<VendorsCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<VendorsEdit />}
                                    />
                                    <Route
                                        path="show/:id"
                                        element={<VendorsShow />}
                                    />
                                </Route>
                                <Route path="/Locations">
                                    <Route index element={<LocationsList />} />
                                    <Route
                                        path="create"
                                        element={<LocationsCreate />}
                                    />
                                </Route>
                                <Route path="/Logins">
                                    <Route index element={<LoginsList />} />
                                    <Route
                                        path="create"
                                        element={<LoginsCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<LoginsEdit />}
                                    />
                                    <Route
                                        path="show/:id"
                                        element={<LoginsShow />}
                                    />
                                </Route>
                                <Route path="/Accounts">
                                    <Route index element={<AccountsList />} />
                                    <Route
                                        path="create"
                                        element={<AccountsCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<AccountsEdit />}
                                    />
                                    <Route
                                        path="show/:id"
                                        element={<AccountsShow />}
                                    />
                                </Route>
                                <Route path="/Bills">
                                    <Route index element={<BillsList />} />
                                    <Route
                                        path="create"
                                        element={<BillsCreate />}
                                    />
                                    {/* <Route path="edit/:id" element={<AccountsEdit />} /> */}
                                    <Route
                                        path="show/:id"
                                        element={<BillsShow />}
                                    />
                                </Route>

                                <Route path="/Tenants">
                                    <Route index element={<TenantsList />} />
                                    <Route
                                        path="create"
                                        element={<TenantsCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<TenantsEdit />}
                                    />
                                    <Route
                                        path="show/:id"
                                        element={<TenantsShow />}
                                    />
                                </Route>
                                <Route path="/Profiles">
                                    <Route index element={<ProfilesList />} />
                                    <Route
                                        path="create"
                                        element={<ProfilesCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<ProfilesEdit />}
                                    />
                                    <Route
                                        path="show/:id"
                                        element={<ProfilesShow />}
                                    />
                                </Route>
                                <Route path="/Notifications">
                                    <Route
                                        index
                                        element={<NotificationsList />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<NotificationsEdit />}
                                    />
                                    <Route
                                        path="show/:id"
                                        element={<NotificationsShow />}
                                    />
                                </Route>

                                {/* <Route
                                    path="/user-profile"
                                    element={<UserProfile />}
                                /> */}

                                <Route path="*" element={<ErrorComponent />} />
                            </Route>
                            <Route
                                element={
                                    <Authenticated
                                        key="authenticated-outer"
                                        fallback={<Outlet />}
                                    >
                                        <NavigateToResource />
                                    </Authenticated>
                                }
                            >
                                <Route
                                    path="/login"
                                    element={
                                        <AuthPage
                                            type="login"
                                            registerLink={false}
                                            formProps={{
                                                defaultValues: {
                                                    email: "",
                                                    password: "",
                                                },
                                            }}
                                        />
                                    }
                                />
                                <Route
                                    path="/forgot-password"
                                    element={<AuthPage type="forgotPassword" />}
                                />
                            </Route>
                        </Routes>
                        <RefineKbar />
                        <UnsavedChangesNotifier />
                        <DocumentTitleHandler />
                    </Refine>
                </RefineSnackbarProvider>
            </RefineKbarProvider>
        </BrowserRouter>
    );
};

export default App;
