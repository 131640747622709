export const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
  { value: "Error", label: "Error" },
];

export const refreshOptions = [
  { value: "Monthly", label: "Monthly" },
  { value: "BiMonthly", label: "BiMonthly" },
  { value: "Daily", label: "Daily" },
];
