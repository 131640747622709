import {
    useShow,
    IResourceComponentsProps,
    useTranslate,
} from "@refinedev/core";
import {
    Show,
    TextFieldComponent as TextField,
    NumberField,
    EmailField,
} from "@refinedev/mui";
import { Typography, Box, Grid, styled, Paper, Link } from "@mui/material";
import { EditButton } from "components/buttons/edit";
import { BackButton } from "components/buttons/back";
import { ListButton } from "components/buttons/list";

export const LoginsShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();

    const { queryResult } = useShow({
        meta: {
            select: "id,Name,vendors(Name),clients(Name),Refresh,RefreshOffset,URI,Username,Password,Status,StatusMessage,Metadata,accounts(count)",
        },
    });
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const identity = JSON.parse(localStorage.getItem("profile") as string);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#212133" : "#fff",
        backgroundImage: "none",
        boxShadow: "none",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: "left",
    }));

    return (
        <Show
            isLoading={isLoading}
            headerButtons={[
                <ListButton
                    key={"list"}
                    sx={{
                        padding: ".25rem 1.6875rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                >
                    View All Logins
                </ListButton>,
            ]}
            footerButtons={[
                <EditButton
                    key={"edit"}
                    sx={{
                        padding: ".25rem 2.25rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                    accessControl={{ enabled: true, hideIfUnauthorized: true }} // For AccessControl settings check the accessControlProvider component.
                >
                    Edit
                </EditButton>,
            ]}
            footerButtonProps={{
                sx: { justifyContent: "flex-end", p: "16px", mb: ".625rem" },
            }}
            goBack={<BackButton />}
            wrapperProps={{
                className: "logins",
            }}
        >
            <Box display="flex">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={3}>
                        <Grid
                            container
                            item
                            spacing={2}
                            xs={12}
                            sm={6}
                            direction="column"
                        >
                            <Grid
                                item
                                container
                                sx={{
                                    justifyItems: "flex-start",
                                    alignItems: "center",
                                    wordBreak: "break-word",
                                }}
                            >
                                <Grid item xs={12} sm={3}>
                                    <Item>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                        >
                                            {translate("Logins.fields.Name")}
                                        </Typography>
                                    </Item>
                                </Grid>
                                <Grid item>
                                    <Item>
                                        <TextField value={record?.Name} />
                                    </Item>
                                </Grid>
                                {(identity.role === "manager" ||
                                    identity.role === "admin") && (
                                    <Grid
                                        item
                                        container
                                        sx={{
                                            justifyItems: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Grid item xs={12} sm={3}>
                                            <Item>
                                                <Typography
                                                    variant="body1"
                                                    fontWeight="bold"
                                                >
                                                    {translate(
                                                        "Logins.fields.Client"
                                                    )}
                                                </Typography>
                                            </Item>
                                        </Grid>
                                        <Grid item>
                                            <Item>
                                                <TextField
                                                    value={
                                                        record?.clients?.Name
                                                    }
                                                />
                                            </Item>
                                        </Grid>
                                    </Grid>
                                )}

                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={3}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Logins.fields.Vendors"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField
                                                value={record?.vendors?.Name}
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={3}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Logins.fields.Status"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField value={record?.Status} />
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={3}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Logins.fields.StatusMessage"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            {record?.StatusMessage ? (
                                                <TextField
                                                    value={
                                                        record?.StatusMessage
                                                    }
                                                />
                                            ) : (
                                                "--"
                                            )}
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={3}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Logins.fields.Refresh"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField
                                                value={record?.Refresh}
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            spacing={2}
                            xs={12}
                            sm={6}
                            direction="column"
                        >
                            <Grid
                                item
                                container
                                sx={{
                                    justifyItems: "flex-start",
                                    alignItems: "center",
                                    wordBreak: "break-word",
                                }}
                            >
                                <Grid item xs={12} sm={3}>
                                    <Item>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                        >
                                            {translate(
                                                "Logins.fields.RefreshOffset"
                                            )}
                                        </Typography>
                                    </Item>
                                </Grid>
                                <Grid item>
                                    <Item>
                                        <NumberField
                                            value={record?.RefreshOffset ?? ""}
                                        />
                                    </Item>
                                </Grid>

                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={3}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate("Logins.fields.URI")}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <Link
                                                href={record?.URI}
                                                target="_blank"
                                            >
                                                {record?.URI}
                                            </Link>
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={3}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Logins.fields.Username"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <EmailField
                                                value={record?.Username}
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={3}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Logins.fields.Password"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField
                                                value={record?.Password}
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        justifyItems: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={3}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {translate(
                                                    "Logins.fields.AccountsCount"
                                                )}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item>
                                        <Item>
                                            <TextField
                                                value={
                                                    record?.accounts[0].count
                                                }
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Show>
    );
};
