import { Link } from "react-router-dom";
import { useList, useMany, useTranslate } from "@refinedev/core";

import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography,
    useTheme,
} from "@mui/material";

// Types
import { useContext } from "react";
import { ColorModeContext } from "contexts/ColorModeContext";
import LoadingIndicator from "components/LoadingIndicator";

interface IUseList {
    id: number;
    Name: string;
}

interface RecentBillsCardProps {
    resource: string;
    title?: string | null;
    slug?: string;
    numberOfItems?: number;
}

export const RecentBillsCard = ({
    resource,
    title,
    slug,
    numberOfItems,
}: RecentBillsCardProps) => {
    const theme = useTheme();
    const translate = useTranslate();
    const { mode } = useContext(ColorModeContext);

    const {
        data,
        isLoading,
        // isError: isErrorDataSinceDate,
    } = useList<IUseList>({
        resource: resource,
        pagination: {
            pageSize: numberOfItems ?? 5,
        },
        sorters: [{ field: "Docdate", order: "desc" }],
    });

    const { data: accountsData } = useMany({
        resource: "accounts",
        ids: data?.data?.map((item: any) => item?.accounts_id) ?? [],
        queryOptions: {
            enabled: !!data?.data,
        },
        meta: {
            fields: ["id"],
        },
    });

    const { data: vendorsData } = useMany({
        resource: "vendors",
        ids: accountsData?.data?.map((item: any) => item?.vendors_id) ?? [],
        queryOptions: {
            enabled: !!accountsData?.data,
        },
        meta: {
            fields: ["id"],
        },
    });

    return (
        <Card
            sx={{
                padding: "2px",
                borderRadius: "10px",
                backgroundColor: mode === "dark" ? "#212133" : "#fff",
                boxSizing: "border-box",
                boxShadow: "0px 10px 15px 0px rgba(0, 0, 0, 0.15)",
                m: "1rem 0",
            }}
        >
            {" "}
            <CardHeader
                sx={{
                    padding: "15px",
                    "& .MuiCardHeader-action": {
                        m: "0 0 0 0",
                    },
                }}
                // title={title}
                subheader={`${translate(
                    "dashboard.recentCard.recent"
                )} ${title}`}
                subheaderTypographyProps={{
                    color:
                        mode === "dark"
                            ? "rgba(255, 255, 255, 0.8)"
                            : "#333333",
                    fontSize: "1.125rem",
                }}
                action={
                    <Typography
                        component={Link}
                        to={`/${slug}`}
                        variant="subtitle1"
                        style={{
                            color: theme.palette.text.primary,
                            fontSize: ".9375rem",
                            textDecoration: "none",
                        }}
                    >
                        {translate("dashboard.recentCard.viewAll")}
                    </Typography>
                }
            />
            {isLoading ? (
                <LoadingIndicator size={50} />
            ) : (
                <CardContent>
                    <Box display="flex">
                        <Box
                            sx={{
                                flexGrow: 1,
                            }}
                        >
                            {data?.data?.map((item: any) => {
                                const account = accountsData?.data?.find(
                                    (account: any) =>
                                        account?.id === item?.accounts_id
                                );

                                const vendor = vendorsData?.data?.find(
                                    (vendor: any) =>
                                        vendor?.id === account?.vendors_id
                                );

                                return (
                                    <Grid
                                        key={item?.id}
                                        container
                                        spacing={2}
                                        direction="row"
                                        style={{
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            margin: "1.25rem 0",
                                            width: "100%",
                                        }}
                                    >
                                        <Grid
                                            item
                                            style={{ padding: "0 0 0 0" }}
                                        >
                                            <Typography
                                                component="div"
                                                style={{
                                                    fontSize: ".9375rem",
                                                }}
                                                color={
                                                    mode === "dark"
                                                        ? "rgba(255, 255, 255, 0.8)"
                                                        : "#212121"
                                                }
                                            >
                                                {/* {item?.Metadata?.Vendor?.Name} */}
                                                {vendor?.Name}
                                            </Typography>
                                            <Typography
                                                component="div"
                                                style={{
                                                    color: theme.palette.text
                                                        .secondary,
                                                    fontSize: ".8125rem",
                                                }}
                                            >
                                                Account:{" "}
                                                {/* {item?.Metadata?.Customer?.Identifier} */}
                                                {account?.Name}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            style={{
                                                padding: "0 0 0 0",
                                                margin: "0 0 0 0",
                                            }}
                                        >
                                            <Typography
                                                component="span"
                                                style={{
                                                    fontSize: ".9375rem",
                                                }}
                                                color={
                                                    mode === "dark"
                                                        ? "rgba(255, 255, 255, 0.8)"
                                                        : "#212121"
                                                }
                                            >
                                                {item?.TotalAmount
                                                    ? "$" + item?.TotalAmount
                                                    : "--"}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            style={{
                                                padding: "0 0 0 0",
                                                margin: "0 0 0 0",
                                            }}
                                        >
                                            <Typography
                                                component="span"
                                                style={{
                                                    color: theme.palette.text
                                                        .secondary,
                                                    fontSize: ".8125rem",
                                                }}
                                            >
                                                {item?.Docdate}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Box>
                    </Box>
                </CardContent>
            )}
        </Card>
    );
};
