import React, { useContext, useState } from "react";
import { ThemedSiderV2 } from "@refinedev/mui";
import { ColorModeContext } from "contexts/ColorModeContext";

export const CustomSider = () => {
    const { mode: colorMode } = useContext(ColorModeContext);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    const handleMouseEnter = (index: number) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    return (
        <div className={`custom-sider ${colorMode}`}>
            <ThemedSiderV2
                render={({ items, logout, collapsed }) => {
                    const collapsedMenu = collapsed
                        ? "collapsed-menu"
                        : "expanded-menu";
                    const getItemClasses = (
                        item: React.ReactNode,
                        index: number
                    ) => {
                        let classes = `custom-sider-item ${colorMode} ${
                            index === hoveredIndex ? "hovered" : ""
                        } ${collapsedMenu}`;
                        if (
                            React.isValidElement(item) &&
                            item.props &&
                            item.props.resource
                        ) {
                            classes += ` ${item.props.resource}`;
                        }
                        return classes;
                    };
                    return (
                        <>
                            {items.map((item, index) => (
                                <div
                                    className={getItemClasses(item, index)}
                                    key={index}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    {item}
                                </div>
                            ))}
                            {/* <div className="custom-sider-item logout">{logout}</div> */}
                        </>
                    );
                }}
            />
        </div>
    );
};
