import React, { useEffect, useState } from "react";
import { Create } from "@refinedev/mui";
import {
    Box,
    CircularProgress,
    Stack,
    Switch,
    TextField,
    FormGroup,
    FormControlLabel,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

import AutoCompleteField from "../../components/AutoCompleteField";
import { FieldValues } from "react-hook-form";

export const NotificationsCreate: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {
        // saveButtonProps,
        refineCore: { queryResult, formLoading, onFinish },
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const NotificationsData = queryResult?.data?.data;

    const [isResolved, setIsResolved] = useState(false);

    useEffect(() => {
        if (NotificationsData) {
            setIsResolved(NotificationsData?.resolved);
        }
    }, [NotificationsData]);

    const onFinishHandler = (data: FieldValues) => {
        const notifications_data = { ...data };

        console.log("Submitting notification page data", notifications_data);

        onFinish(notifications_data);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsResolved(event.target.checked);
    };

    return (
        <Create saveButtonProps={{ onClick: handleSubmit(onFinishHandler) }}>
            {formLoading ? (
                <Stack alignItems="center" sx={{ height: "100vh" }}>
                    <CircularProgress size={100} />
                </Stack>
            ) : (
                <Box
                    component="form"
                    sx={{ display: "flex", flexDirection: "column" }}
                    autoComplete="off"
                >
                    <TextField
                        {...register("title", {
                            required: "This field is required",
                        })}
                        error={!!(errors as any)?.title}
                        helperText={(errors as any)?.title?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate("Profiles.fields.Title")}
                        name="title"
                    />
                    <AutoCompleteField
                        id="tenant_id"
                        control={control}
                        resource="tenants"
                        field="tenant_id"
                        name="Name"
                        defaultValue={NotificationsData?.tenant_id}
                        label={translate("Notifications.fields.Tenant")}
                        error={!!(errors as any)?.tenant_id}
                        helperText={(errors as any)?.tenant_id?.message}
                    />
                    <TextField
                        {...register("details")}
                        error={!!(errors as any)?.details}
                        helperText={(errors as any)?.details?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate("Notifications.fields.Details")}
                        name="details"
                    />
                    <TextField
                        {...register("notes")}
                        error={!!(errors as any)?.notes}
                        helperText={(errors as any)?.notes?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate("Notifications.fields.Notes")}
                        name="notes"
                    />
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    {...register("resolved")}
                                    checked={isResolved}
                                    onChange={handleChange}
                                    inputProps={{ "aria-label": "resolved" }}
                                />
                            }
                            label={translate("Notifications.fields.Resolved")}
                        />
                    </FormGroup>
                </Box>
            )}
        </Create>
    );
};
