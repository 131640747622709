import { createClient } from "@refinedev/supabase";

export const supabaseClient = createClient(
    process.env.REACT_APP_SUPABASE_URL as string,
    process.env.REACT_APP_SUPABASE_KEY as string,
    {
        db: {
            schema: "public",
        },
        auth: {
            // autoRefreshToken: false, // All my Supabase access is from server, so no need to refresh the token
            // detectSessionInUrl: false, // We are not using OAuth, so we don't need this. Also, we are manually "detecting" the session in the server-side code
            // persistSession: false, // All our access is from server, so no need to persist the session to browser's local storage

            persistSession: true,
        },
    }
);
