import React from "react";

import { TextField, Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";
import { useAutocomplete } from "@refinedev/mui";

interface Props {
  id?: any;
  control?: any;
  resource?: string;
  name?: string;
  field?: string;
  label?: string;
  defaultValue?: any;
  error?: any;
  helperText?: any;
  disabled?: boolean;
}

const AutoCompleteField = ({
  control,
  resource,
  name,
  field,
  label,
  defaultValue,
  error,
  helperText,
  disabled,
}: Props) => {
  const { autocompleteProps: fieldAutocompleteProps } = useAutocomplete({
    resource: resource as string,
    defaultValue: defaultValue,
    meta: {
      select: `id,${name}`,
    },
    sorters: [
      {
        field: name as string,
        order: "asc",
      },
    ],
    onSearch: (value) =>
      [
        {
          field: name,
          operator: "contains",
          value,
        },
      ] as any,
    debounce: 500,
  });

  return (
    <Controller
      control={control}
      name={field as string}
      rules={{ required: "This field is required" }}
      // eslint-disable-next-line
      defaultValue={null as any}
      render={({ field }) => {
        return (
          <Autocomplete
            {...fieldAutocompleteProps}
            {...field}
            onChange={(_, value) => {
              field.onChange(value?.id ?? value);
            }}
            getOptionLabel={(item) => {
              return (
                fieldAutocompleteProps?.options?.find(
                  (p) => p?.id?.toString() === (item?.id ?? item)?.toString()
                )?.Name ?? ""
              );
            }}
            isOptionEqualToValue={(option, value) => {
              return (
                value === undefined ||
                option?.id?.toString() === (value?.id ?? value)?.toString()
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                margin="normal"
                variant="outlined"
                error={error}
                helperText={helperText}
                required
              />
            )}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

export default AutoCompleteField;
