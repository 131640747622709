import { Box, Divider, Popover, TextField } from "@mui/material";
import { useSharedState } from "contexts/SharedStateContext";
import { Edit } from "@refinedev/mui";
import { CancelButton } from "components/buttons/cancel";
import { useForm } from "@refinedev/react-hook-form";
import { FieldValues } from "react-hook-form";
import { SaveButton } from "components/buttons/save";

export const SavingsCardPopover = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sharedState, setSharedState } = useSharedState();
    const identity = JSON.parse(localStorage.getItem("profile") as string);

    const {
        refineCore: { onFinish, queryResult },
        register,
        handleSubmit,
        saveButtonProps,
        formState: { errors },
    } = useForm({
        refineCoreProps: {
            resource: "tenants",
            action: "edit",
            id: identity?.tenant_id,
            redirect: false,
            meta: {
                fields: [
                    "Name",
                    "Metadata.last_month_savings",
                    "Metadata.percent_earnings",
                ], // These fields will be shown in the form
            },
            successNotification: (_data, _values, _resource) => {
                return {
                    message: "Savings Card updated successfully",
                    // description: "The post has been updated without any errors.",
                    type: "success",
                };
            },
        },
    });

    const onFinishHandler = async (data: FieldValues) => {
        console.log("SUBMITTING DATA", data);
        await onFinish(data);

        handleSavingsCardPopoverClose();
    };

    const handleSavingsCardPopoverClose = () => {
        setSharedState((prevState) => ({
            ...prevState,
            anchorElSavingsCard: null,
        }));
    };

    const savingsCardPopoverOpen = Boolean(sharedState?.anchorElSavingsCard);
    const savingsId = savingsCardPopoverOpen
        ? "simple-savings-popover"
        : undefined;

    return (
        <Popover
            id={savingsId}
            open={savingsCardPopoverOpen}
            anchorEl={sharedState?.anchorElSavingsCard}
            onClose={handleSavingsCardPopoverClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            slotProps={{
                paper: {
                    sx: (theme) => {
                        return {
                            width: "360px",
                            backgroundColor:
                                theme.palette.mode === "light"
                                    ? "#fff"
                                    : "#313142",
                            backgroundImage: "none",
                        };
                    },
                },
            }}
        >
            <Box display="flex" flexDirection="column" maxHeight="486px">
                {/* Header */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: "1rem",
                    }}
                >
                    <Box
                        sx={(theme) => ({
                            display: "flex",
                            alignItems: "center",
                            fontSize: "1rem",
                            fontFamily:
                                '"ProductSans-Bold", "Product Sans Bold", "Product Sans", sans-serif',
                            fontWeight: 700,
                            color:
                                theme.palette.mode === "light"
                                    ? "#212121"
                                    : "rgba(255, 255, 255, 0.8)",
                        })}
                    >
                        Savings Card Edit
                    </Box>
                </Box>
                <Divider />

                {/* Scrollable Content */}
                <Box flex={1} overflow="auto">
                    <Box p="0 16px">
                        <Edit
                            resource="tenants"
                            recordItemId={identity?.tenant_id}
                            headerProps={{ style: { display: "none" } }}
                            saveButtonProps={saveButtonProps}
                            footerButtons={[
                                <CancelButton
                                    key={"cancel"}
                                    sx={{
                                        padding: ".25rem 2.25rem",
                                        minWidth: "7.5rem",
                                        textTransform: "capitalize",
                                    }}
                                    onClick={handleSavingsCardPopoverClose}
                                />,
                                <SaveButton
                                    key={"save"}
                                    sx={{
                                        padding: ".25rem 2.25rem",
                                        minWidth: "7.5rem",
                                        textTransform: "capitalize",
                                    }}
                                    onClick={handleSubmit(onFinishHandler)}
                                >
                                    Update
                                </SaveButton>,
                            ]}
                            wrapperProps={{
                                className: "tenants",
                                sx: {
                                  backgroundColor: "transparent",
                                  border: "none",
                                  boxShadow: "none",
                                },
                            }}
                        >
                            <Box
                                component="form"
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                                autoComplete="off"
                            >
                                <TextField
                                    {...register("Name", {
                                        required: "Name is required",
                                    })}
                                    error={!!(errors as any)?.Name}
                                    helperText={(errors as any)?.Name?.message}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="Name"
                                    label="Name"
                                    name="Name"
                                    defaultValue={
                                        queryResult?.data?.data?.Name || ""
                                    }
                                    size="small"
                                    disabled
                                />
                                <TextField
                                    {...register(
                                        "Metadata.last_month_savings",
                                        {
                                            required:
                                                "Last Month Savings is required",
                                        }
                                    )}
                                    error={
                                        !!(errors as any)?.Metadata
                                            ?.last_month_savings?.message
                                    }
                                    helperText={
                                        (errors as any)?.Metadata
                                            ?.last_month_savings?.message
                                    }
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="last_month_savings"
                                    label="Last Month Savings"
                                    name="Metadata.last_month_savings"
                                    defaultValue={
                                        queryResult?.data?.data?.Metadata
                                            ?.last_month_savings || ""
                                    }
                                    size="small"
                                />
                                <TextField
                                    {...register("Metadata.percent_earnings", {
                                        required:
                                            "Percent Earnings is required",
                                    })}
                                    error={
                                        !!(errors as any)?.Metadata
                                            ?.percent_earnings?.message
                                    }
                                    helperText={
                                        (errors as any)?.Metadata
                                            ?.percent_earnings?.message
                                    }
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="percent_earnings"
                                    label="Percent Earnings"
                                    name="Metadata.percent_earnings"
                                    defaultValue={
                                        queryResult?.data?.data?.Metadata
                                            ?.percent_earnings || ""
                                    }
                                    size="small"
                                />
                            </Box>
                        </Edit>
                    </Box>
                </Box>
            </Box>
        </Popover>
    );
};
